<template>
  <div>
    <div class="container-fluid px-0 py-2">
      <div class="col-12 px-4">
        <div class="col-12">
          <div class="row">
            <div
              class="col-12 col-md-12 col-lg-3 px-0"
              style="max-width: 350px"
            >
              <div class="col-12 pt-2 pb-0 d-none d-lg-block"></div>
              <div class="col-6 col-md-6 col-lg-12 p-0 d-none d-lg-block">
                <div class="col-12">
                  <BaseShopMenu
                    :kits="kitsList"
                    :kit="kit"
                    @clicked="fetchKit"
                    :order="order"
                  />
                </div>
              </div>
            </div>
            <div class="col-12 col-md-12 col-lg-9 mx-auto px-1 pt-3">
              <div class="col-12">
                <div class="row">
                  <div
                    class="col-6 col-md-6 text-lg-right text-left pt-3 d-lg-none d-block"
                  >
                    <img
                      src="~@/assets/img/hub_/hub-logo.svg"
                      class="img-fluid mb-3"
                      title="Logo"
                      style="max-height: 40px"
                    />
                  </div>
                  <div class="col-6 col-md-6 d-lg-none d-block text-right pt-2">
                    <a
                      data-bs-toggle="modal"
                      data-bs-target="#menu-product"
                      href="javascript:void(0);"
                    >
                      <button
                        type="submit"
                        class="font-14 font-b-5 btn btn-dark pulse-mvp text-nowrap"
                      >
                        <i class="fas fa-bars"></i> KITS
                      </button>
                    </a>
                  </div>
                  <div class="col-12">
                    <div class="row">
                      <div
                        class="col-12 col-md-6 col-lg-6 pb-3 pb-sm-4 align-self-end"
                      >
                        <img
                          v-if="kit.enterprise.image"
                          :src="kit.enterprise.image"
                          class="img-fluid mb-3 d-none d-lg-block"
                          title="Logo"
                          style="max-height: 40px"
                        />
                        <h3
                          class="font-b-6 mb-2 mt-2"
                          style="color: var(--color)"
                        >
                          {{ kit.name }}
                        </h3>
                        <p class="mb-4">{{ kit.description }}</p>
                        <p class="mb-1">VALOR TOTAL DO KIT</p>
                        <h2 style="color: var(--color); font-weight: 500">
                          {{ formatMoney(kit.price) }}
                        </h2>
                        <div class="w-100 pt-2">
                          <a
                            data-bs-toggle="modal"
                            data-bs-target="#right-modal-kit"
                            href="#right-modal-kit"
                          >
                            <button
                              type="submit"
                              class="btn btn-info"
                              style="background-color: var(--color)"
                              @click="addKart"
                            >
                              <b>ADICIONAR</b> AO CARRINHO
                            </button>
                          </a>
                        </div>
                      </div>
                      <div class="col-12 col-md-6 col-lg-6 py-2 py-sm-2">
                        <div class="w-100 pb-3">
                          <div
                            id="carouselExampleIndicators3"
                            class="carousel slide"
                            data-bs-ride="carousel"
                          >
                            <ol class="carousel-indicators">
                              <li
                                data-bs-target="#carouselExampleIndicators3"
                                :class="{ active: index == 0 }"
                                :data-bs-slide-to="index"
                                v-for="(item, index) in kit.gallery.images"
                                :key="item.id"
                              ></li>
                            </ol>
                            <div class="carousel-inner">
                              <div
                                class="carousel-item text-center"
                                :class="{ active: index == 0 }"
                                v-for="(item, index) in kit.gallery.images"
                                :key="item.id"
                              >
                                <img
                                  class="img-fluid"
                                  :src="item.path"
                                  alt="First slide"
                                  style="border-radius: 12px; max-height: 330px"
                                />
                                <div class="carousel-caption text-left">
                                  <div
                                    class="d-auto"
                                    style="
                                      border-bottom: 2px var(--color) solid;
                                      margin-bottom: 20px;
                                      padding-left: 0px;
                                    "
                                  >
                                    <h3 class="text-white font-14">
                                      {{ item.subtitle }}
                                    </h3>
                                  </div>
                                  <!-- <p class="text-white d-none">Apartamento com 01 suíte, 1quarto e lavabo</p> -->
                                </div>
                              </div>
                            </div>
                            <a
                              class="carousel-control-prev"
                              href="#carouselExampleIndicators3"
                              role="button"
                              data-bs-slide="prev"
                            >
                              <span
                                class="icon-arrow-left font-28"
                                aria-hidden="true"
                                style="
                                  color: white;
                                  text-shadow: #666 0.1em 0.1em 0.2em;
                                "
                              ></span>
                              <span class="sr-only">Previous</span>
                            </a>
                            <a
                              class="carousel-control-next"
                              href="#carouselExampleIndicators3"
                              role="button"
                              data-bs-slide="next"
                            >
                              <span
                                class="icon-arrow-right font-28"
                                aria-hidden="true"
                                style="
                                  color: white;
                                  text-shadow: #666 0.1em 0.1em 0.2em;
                                "
                              ></span>
                              <span class="sr-only">Next</span>
                            </a>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- GRADE -->
                  <template v-if="kit.exhibition == 'GRADE'">
                    <div
                      class="col-12"
                      v-for="(item, key) in kit.products"
                      :key="key"
                    >
                      <div class="col-12 col-md-4 col-lg-12">
                        <h3 class="font-b-1 mb-2 mt-2" style="color: #ff9300">
                          |
                          <span class="mb-0 font-b-3" style="color: #7e7e7e">{{
                            key
                          }}</span>
                        </h3>
                        <hr />
                      </div>
                      <div class="row">
                        <div
                          class="col-12 col-md-4 col-lg-3 p-dash-cliente-master pb-4"
                          v-for="product in item"
                          :key="product.id"
                        >
                          <div class="card bg-white mb-0 h-100">
                            <div class="card-body px-3 pt-4 pb-1">
                              <div
                                class="px-2 pb-2 text-center d-image-product align-items-center d-flex text-center"
                              >
                                <img
                                  :src="product.product.image"
                                  class="img-fluid mx-auto"
                                  alt="Qsuíte"
                                  style="max-height: 115px"
                                />
                              </div>
                              <div class="">
                                <h2 class="font-15 font-b-6 mb-1 pt-2">
                                  {{ product.product.quantity }}
                                  {{ product.product.name }}
                                </h2>
                                <p class="font-11">
                                  Referência:{{ product.product.reference }}
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>

                  <!-- LIST -->
                  <template v-if="kit.exhibition == 'LIST'">
                    <div
                      class="col-12"
                      v-for="(item, key) in kit.products"
                      :key="key"
                    >
                      <div class="col-12 col-md-4 col-lg-12">
                        <h3 class="font-b-1 mb-2 mt-2" style="color: #ff9300">
                          |
                          <span class="mb-0 font-b-3" style="color: #7e7e7e">{{
                            key
                          }}</span>
                        </h3>
                        <hr />
                      </div>
                      <div class="col-12 py-2 mb-2 px-0 line-box-list">
                        <div class="col-12">
                          <div class="row">
                            <div
                              class="col-8 col-md-8 col-lg-8 p-0 align-self-center"
                            >
                              <div class="col-12">
                                <div class="row">
                                  <div
                                    class="col-3 col-md-auto col-lg-auto align-self-center text-center"
                                  >
                                    <h2 class="font-11 font-b-6 pt-2 pl-2">
                                      QTD
                                    </h2>
                                  </div>
                                  <div
                                    class="col-6 col-md-7 col-lg-8 text-left align-self-center p-0 text-center"
                                  >
                                    <p class="mb-0 font-11 font-b-6">TIPO</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                            <div
                              class="col-4 col-md-4 col-lg-4 p-0 align-self-center"
                            >
                              <div class="col-12">
                                <div class="row">
                                  <div
                                    class="col-12 col-md-6 col-lg-6 align-self-center p-0"
                                  >
                                    <p
                                      class="mb-0 font-b-6 font-11 d-none"
                                      style="color: #ff9300"
                                    >
                                      VALOR UNITÁRIO
                                    </p>
                                  </div>
                                  <div
                                    class="col-12 col-md-6 col-lg-6 align-self-center p-0"
                                  >
                                    <p
                                      class="mb-0 font-b-6 font-11 d-none"
                                      style="color: #212121"
                                    >
                                      VALOR TOTAL
                                    </p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>

                      <div
                        class="col-12 p-dash-cliente-master pb-2"
                        v-for="product in item"
                        :key="product.id"
                        v-show="kit.exhibition == 'LIST'"
                      >
                        <div class="card line-box-list mb-0 h-100">
                          <div class="pb-2">
                            <div class="col-12">
                              <div class="row">
                                <div
                                  class="col-9 col-md-8 col-lg-8 p-0 align-self-center"
                                >
                                  <div class="col-12">
                                    <div class="row">
                                      <div
                                        class="col-3 col-md-auto align-self-center"
                                      >
                                        <h2 class="font-14 font-b-6 pt-2">
                                          {{ product.product.quantity }}
                                        </h2>
                                      </div>
                                      <div
                                        class="col-6 col-md-7 col-lg-9 text-left align-self-center pl-0 pr-auto"
                                      >
                                        <p class="mb-0 font-13">
                                          {{ product.product.name }}
                                        </p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                                <div
                                  class="col-3 col-md-4 col-lg-4 p-0 align-self-center"
                                >
                                  <div class="col-12">
                                    <div class="row">
                                      <div
                                        class="col-12 col-md-6 col-lg-6 align-self-center p-0"
                                      >
                                        <p
                                          class="mb-0 font-b-6 font-14"
                                          style="color: #ff9300"
                                        ></p>
                                      </div>
                                      <div
                                        class="col-12 col-md-6 col-lg-6 align-self-center p-0"
                                      >
                                        <p
                                          class="mb-0 font-b-6 font-14"
                                          style="color: #212121"
                                        ></p>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </template>
                  <!-- FIM LIST -->

                  <div class="col-12">
                    <div class="row">
                      <div
                        class="col-12 col-md-6 col-lg-8 pb-2 order-2 order-sm-1"
                      >
                        <div class="w-100 text-left text-sm-left">
                          <a
                            data-bs-toggle="modal"
                            data-bs-target="#right-modal-kit"
                            href="#right-modal-kit"
                          >
                            <button
                              type="submit"
                              class="btn btn-info"
                              style="background-color: var(--color)"
                              @click="addKart"
                            >
                              <b>ADICIONAR</b> AO CARRINHO
                            </button>
                          </a>
                        </div>
                      </div>
                      <div
                        class="col-12 col-md-6 col-lg-auto pb-2 d-auto ml-auto order-1 order-sm-2"
                      >
                        <div
                          class="d-auto ml-auto w-100 text-left text-sm-right"
                        >
                          <h2 class="font-15 font-b-3 mb-0 pt-2">
                            VALOR TOTAL DO KIT
                          </h2>
                          <p
                            class="font-b-4 font-40 mb-1"
                            style="color: var(--color)"
                          >
                            R$ {{ formatMoney(kit.price) }}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>

                  <BaseClientKitConditions />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- footer kit -->
    <div
      class="col-12 px-4 d-flex align-items-center"
      style="
        position: fixed;
        bottom: 0;
        background: #212121;
        height: 80px;
        z-index: 99;
      "
    >
      <div class="d-auto mx-auto align-self-center">
        <div class="col-12">
          <div class="row">
            <div
              class="col-12 col-md-12 col-lg-auto align-self-center text-center"
            >
              <p class="font-b-4 font-13 text-white mb-0">
                TOTAL
                <span
                  class="font-30 fontm-15 font-b-5 mb-0"
                  style="color: #ffffff"
                  >R$ {{ formatMoney(totalKart) }}</span
                >
              </p>
            </div>
            <div
              class="col-12 col-md-12 col-lg-auto align-self-center text-center px-0"
            >
              <a
                data-bs-toggle="modal"
                data-bs-target="#right-modal-kit"
                href="#right-modal-kit"
                class="mr-2"
              >
                <button
                  type="submit"
                  class="btn btn-info font-14 font-b-5"
                  style="background-color: #ffffff; color: #212121"
                >
                  VER CARRINHO
                </button>
              </a>
              <a
                data-bs-toggle="modal"
                data-bs-target="#right-modal-kit"
                href="#right-modal-kit"
              >
                <button
                  type="submit"
                  class="btn btn-info font-14 font-b-5"
                  style="background-color: var(--color)"
                >
                  ENVIAR PEDIDO
                </button>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <Checkout :order="order" @updateTotal="updateTotal" />

    <!-- Menu mobile -->
    <div
      id="menu-product"
      class="modal fade"
      tabindex="-1"
      role="dialog"
      aria-hidden="true"
    >
      <div
        class="modal-dialog modal-lg modal-right h-100 d-flex justify-content-start"
        style="border-radius: 0 0 0 18px !important"
      >
        <div class="modal-content" style="border-radius: 0 0 0 18px !important">
          <div class="modal-header border-0">
            <button
              type="button"
              class="close"
              data-bs-dismiss="modal"
              aria-hidden="true"
            >
              <i class="ti-close font-20"></i>
            </button>
          </div>
          <div class="modal-body">
            <div class="h-100" style="min-width: 350px">
              <div class="col-12">
                <BaseShopMenu
                  :kits="kitsList"
                  :kit="kit"
                  @clicked="fetchKit"
                  :order="order"
                />
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import KitService from "@/services/resources/KitService";
import Checkout from "@/components/Kit/Checkout.vue";
import Money from "@/mixins/money";
const service = KitService.build();

export default {
  mixins: [Money],
  components: {
    Checkout,
  },
  props: {
    itens: {
      type: Array,
    },
    enterprise_id: {
      type: Number,
    },
    unity_id: {
      type: Number,
    },
    tipology_id: {
      type: Number,
    },
  },
  data() {
    return {
      kit: {
        name: null,
        description: null,
        exhibition: null,
        price: 0,
        images: [],
        products: [],
        enterprise: {
          image: null,
        },
        gallery: {
          images: [],
        },
      },
      kits: [],
      totalKart: 0,
      order: {
        user_id: 0,
        unity_id: null,
        enterprise_id: null,
        tipology_id: null,
        itens: [],
        kits: [],
      },
    };
  },
  computed: {
    user() {
      return this.$store.getters.getProfile;
    },
    total() {
      let sum = 0;

      if (this.order.kits) {
        for (let i = 0; i < this.order.kits.length; i++) {
          sum += this.order.kits[i].price ? this.order.kits[i].price : 0;
        }
      }

      if (this.order.itens) {
        for (let i = 0; i < this.order.itens.length; i++) {
          sum += this.order.itens[i].price ? this.order.itens[i].price : 0;
        }
      }

      return sum;
    },
    isShow() {
      return (
        this.kits.length > 0 &&
        this.kits[0].id != this.kit.id &&
        this.order.kits.length > 0
      );
    },
    kitsList() {
      return this.kits
        .filter((elem) => {
          if (elem.is_decoration) {
            if (this.order.kits.length > 0) {
              for (let i = 0; i < this.order.kits.length; i++) {
                if (this.order.kits[i].decoration_kit_id == elem.id) {
                  return true;
                }
              }
              return false;
            }
          }

          return true;
        })
        .sort((a, b) => {
          return a.order < b.order ? -1 : a.order > b.order ? 1 : 0;
        });
    },
  },
  methods: {
    addKart() {
      for (let i = 0; i < this.order.kits.length; i++) {
        if (this.order.kits[i].id == this.kit.id) {
          return;
        }
      }
      if (this.kit.master_kit == 1) {
        for (let i = 0; i < this.order.kits.length; i++) {
          if (this.order.kits[i].master_kit == 1) {
            this.$bvToast.toast(
              "Você não pode adicionar dois kits master no mesmo carrinho.",
              {
                title: "Kit",
                autoHideDelay: 5000,
                type: "danger",
              }
            );
            return;
          }
        }
      }
      if (
        this.kit.type == "MAIN" &&
        (this.kit.free_secondary == null || this.kit.free_secondary)
      ) {
        this.$bvToast.toast("Os kits combos foram liberados.", {
          title: "Kit",
          autoHideDelay: 5000,
          type: "success",
        });
      }
      this.order.kits.push(this.kit);
      this.totalKart += this.kit.price;
    },
    updateTotal(total) {
      this.totalKart = total > 0 ? total : this.kit.price;
    },
    fetchKits() {
      let data = {
        enterprise_id: this.order.enterprise_id,
        tipology_id: this.tipology_id,
      };

      service
        .search(data)
        .then((resp) => {
          this.kits = resp;
          this.kits = [
            {
              id: 12,
              name: "KIT_CONNECT",
              description:
                "Fornecimento e instala\u00e7\u00e3o de sistema de \u00e1udio e automa\u00e7\u00e3o. Incluso: Sistema de \u00e1udio com 02 caixas de teto e conex\u00e3o Bluetooth, automa\u00e7\u00e3o de at\u00e9 04 circuitos de ilumina\u00e7\u00e3o (liga/desliga), 01 fechadura eletr\u00f4nica com acesso por senha, 01 assistente virtual de voz.",
              exhibition: "LIST",
              price: 36028.25,
              category_id: 1,
              gallery_id: 1,
              enterprise_id: 13,
              company_id: 4,
              deleted: 0,
              created_at: "2022-04-06T20:19:41.000000Z",
              updated_at: "2022-09-15T16:37:58.000000Z",
              type: "MAIN",
              decoration_kit_id: null,
              is_decoration: 0,
              order: 3,
              free_secondary: 0,
              master_kit: null,
              products: [
                {
                  id: 15387,
                  kit_id: 12,
                  product_id: 158,
                  created_at: "2022-09-15T16:37:58.000000Z",
                  updated_at: "2022-09-15T16:37:58.000000Z",
                },
              ],
              tipologies: [
                {
                  id: 3784,
                  kit_id: 12,
                  tipology_id: 20,
                  created_at: "2022-09-15T16:37:58.000000Z",
                  updated_at: "2022-09-15T16:37:58.000000Z",
                  tipology: {
                    id: 20,
                    name: "TIPO 05\t- 72,08m\u00b2",
                    description: "Studio com banho e terra\u00e7o.",
                    image:
                      "https://qsuite-buckets.s3.us-west-2.amazonaws.com/tipologies/dO0KyjaRjh.jpg",
                    company_id: 4,
                    created_at: "2022-03-16T13:22:00.000000Z",
                    updated_at: "2022-08-19T17:08:25.000000Z",
                    code: "T5P1",
                    color: "#c800ff",
                  },
                },
                {
                  id: 3785,
                  kit_id: 12,
                  tipology_id: 28,
                  created_at: "2022-09-15T16:37:58.000000Z",
                  updated_at: "2022-09-15T16:37:58.000000Z",
                  tipology: {
                    id: 28,
                    name: "TIPO 05\t- 37,89m\u00b2",
                    description: "Studio com banho e laje t\u00e9cnica.",
                    image:
                      "https://qsuite-buckets.s3.us-west-2.amazonaws.com/tipologies/9P86YXGDQQ.jpg",
                    company_id: 4,
                    created_at: "2022-03-16T13:28:00.000000Z",
                    updated_at: "2022-08-19T17:13:38.000000Z",
                    code: "T5P9",
                    color: "#c800ff",
                  },
                },
                {
                  id: 3786,
                  kit_id: 12,
                  tipology_id: 21,
                  created_at: "2022-09-15T16:37:58.000000Z",
                  updated_at: "2022-09-15T16:37:58.000000Z",
                  tipology: {
                    id: 21,
                    name: "TIPO 05\t- 47,01m\u00b2",
                    description: "Studio com banho e balc\u00e3o.",
                    image:
                      "https://qsuite-buckets.s3.us-west-2.amazonaws.com/tipologies/FkdjpB3Had.jpg",
                    company_id: 4,
                    created_at: "2022-03-16T13:22:45.000000Z",
                    updated_at: "2022-08-19T17:08:59.000000Z",
                    code: "T5P2",
                    color: "#c800ff",
                  },
                },
                {
                  id: 3787,
                  kit_id: 12,
                  tipology_id: 22,
                  created_at: "2022-09-15T16:37:58.000000Z",
                  updated_at: "2022-09-15T16:37:58.000000Z",
                  tipology: {
                    id: 22,
                    name: "TIPO 05\t- 52,49m\u00b2",
                    description: "Studio com banho e balc\u00e3o.",
                    image:
                      "https://qsuite-buckets.s3.us-west-2.amazonaws.com/tipologies/lipFkfGJZn.jpg",
                    company_id: 4,
                    created_at: "2022-03-16T13:23:16.000000Z",
                    updated_at: "2022-08-19T16:41:02.000000Z",
                    code: "T5P3",
                    color: "#c800ff",
                  },
                },
                {
                  id: 3788,
                  kit_id: 12,
                  tipology_id: 23,
                  created_at: "2022-09-15T16:37:58.000000Z",
                  updated_at: "2022-09-15T16:37:58.000000Z",
                  tipology: {
                    id: 23,
                    name: "TIPO 05\t- 60,71m\u00b2",
                    description: "Studio com banho e laje t\u00e9cnica.",
                    image:
                      "https://qsuite-buckets.s3.us-west-2.amazonaws.com/tipologies/Hx7LLObb0s.jpg",
                    company_id: 4,
                    created_at: "2022-03-16T13:23:46.000000Z",
                    updated_at: "2022-08-19T17:10:36.000000Z",
                    code: "T5P4",
                    color: "#c800ff",
                  },
                },
                {
                  id: 3789,
                  kit_id: 12,
                  tipology_id: 24,
                  created_at: "2022-09-15T16:37:58.000000Z",
                  updated_at: "2022-09-15T16:37:58.000000Z",
                  tipology: {
                    id: 24,
                    name: "TIPO 05\t- 66,37m\u00b2",
                    description: "Studio com banho e terra\u00e7o.",
                    image:
                      "https://qsuite-buckets.s3.us-west-2.amazonaws.com/tipologies/nrmAcKaLMR.jpg",
                    company_id: 4,
                    created_at: "2022-03-16T13:24:14.000000Z",
                    updated_at: "2022-08-19T17:11:15.000000Z",
                    code: "T5P5",
                    color: "#c800ff",
                  },
                },
                {
                  id: 3790,
                  kit_id: 12,
                  tipology_id: 25,
                  created_at: "2022-09-15T16:37:58.000000Z",
                  updated_at: "2022-09-15T16:37:58.000000Z",
                  tipology: {
                    id: 25,
                    name: "TIPO 05\t- 66,43m\u00b2",
                    description: "Studio com banho e terra\u00e7o.",
                    image:
                      "https://qsuite-buckets.s3.us-west-2.amazonaws.com/tipologies/k1p0tOXzf7.jpg",
                    company_id: 4,
                    created_at: "2022-03-16T13:24:41.000000Z",
                    updated_at: "2022-08-19T17:11:52.000000Z",
                    code: "T5P6",
                    color: "#c800ff",
                  },
                },
                {
                  id: 3791,
                  kit_id: 12,
                  tipology_id: 26,
                  created_at: "2022-09-15T16:37:58.000000Z",
                  updated_at: "2022-09-15T16:37:58.000000Z",
                  tipology: {
                    id: 26,
                    name: "TIPO 05\t- 51,44m\u00b2",
                    description: "Studio com banho e balc\u00e3o.",
                    image:
                      "https://qsuite-buckets.s3.us-west-2.amazonaws.com/tipologies/8Vqb4WjPj6.jpg",
                    company_id: 4,
                    created_at: "2022-03-16T13:25:08.000000Z",
                    updated_at: "2022-08-19T17:12:33.000000Z",
                    code: "T5P7",
                    color: "#c800ff",
                  },
                },
                {
                  id: 3792,
                  kit_id: 12,
                  tipology_id: 27,
                  created_at: "2022-09-15T16:37:58.000000Z",
                  updated_at: "2022-09-15T16:37:58.000000Z",
                  tipology: {
                    id: 27,
                    name: "TIPO 05\t- 47,01m\u00b2",
                    description: "Studio com banho e balc\u00e3o.",
                    image:
                      "https://qsuite-buckets.s3.us-west-2.amazonaws.com/tipologies/976rjj2xVO.jpg",
                    company_id: 4,
                    created_at: "2022-03-16T13:27:07.000000Z",
                    updated_at: "2022-08-19T17:13:18.000000Z",
                    code: "T5P8",
                    color: "#c800ff",
                  },
                },
                {
                  id: 3793,
                  kit_id: 12,
                  tipology_id: 44,
                  created_at: "2022-09-15T16:37:58.000000Z",
                  updated_at: "2022-09-15T16:37:58.000000Z",
                  tipology: {
                    id: 44,
                    name: "TIPO 05 - 51,50m\u00b2",
                    description: "Studio com banho e balc\u00e3o.",
                    image:
                      "https://qsuite-buckets.s3.us-west-2.amazonaws.com/tipologies/hooYXdqABD.jpg",
                    company_id: 4,
                    created_at: "2022-09-15T16:28:38.000000Z",
                    updated_at: "2022-09-15T16:28:56.000000Z",
                    code: "T5P7*",
                    color: "#c800ff",
                  },
                },
              ],
            },
            {
              id: 26,
              name: "KIT_INVEST",
              description:
                "Kit com m\u00f3veis, arm\u00e1rios, eletrodom\u00e9sticos, ar condicionados, ilumina\u00e7\u00e3o e acess\u00f3rios sanit\u00e1rios.",
              exhibition: "GRADE",
              price: 102377.6,
              category_id: 1,
              gallery_id: 29,
              enterprise_id: 13,
              company_id: 4,
              deleted: 0,
              created_at: "2022-04-07T18:23:21.000000Z",
              updated_at: "2022-09-15T16:38:17.000000Z",
              type: "MAIN",
              decoration_kit_id: 36,
              is_decoration: 0,
              order: 2,
              free_secondary: 1,
              master_kit: 1,
              products: [
                {
                  id: 15388,
                  kit_id: 26,
                  product_id: 21,
                  created_at: "2022-09-15T16:38:17.000000Z",
                  updated_at: "2022-09-15T16:38:17.000000Z",
                },
                {
                  id: 15389,
                  kit_id: 26,
                  product_id: 14,
                  created_at: "2022-09-15T16:38:17.000000Z",
                  updated_at: "2022-09-15T16:38:17.000000Z",
                },
                {
                  id: 15390,
                  kit_id: 26,
                  product_id: 11,
                  created_at: "2022-09-15T16:38:17.000000Z",
                  updated_at: "2022-09-15T16:38:17.000000Z",
                },
                {
                  id: 15391,
                  kit_id: 26,
                  product_id: 8,
                  created_at: "2022-09-15T16:38:17.000000Z",
                  updated_at: "2022-09-15T16:38:17.000000Z",
                },
                {
                  id: 15392,
                  kit_id: 26,
                  product_id: 22,
                  created_at: "2022-09-15T16:38:17.000000Z",
                  updated_at: "2022-09-15T16:38:17.000000Z",
                },
                {
                  id: 15393,
                  kit_id: 26,
                  product_id: 33,
                  created_at: "2022-09-15T16:38:17.000000Z",
                  updated_at: "2022-09-15T16:38:17.000000Z",
                },
                {
                  id: 15394,
                  kit_id: 26,
                  product_id: 34,
                  created_at: "2022-09-15T16:38:17.000000Z",
                  updated_at: "2022-09-15T16:38:17.000000Z",
                },
                {
                  id: 15395,
                  kit_id: 26,
                  product_id: 36,
                  created_at: "2022-09-15T16:38:17.000000Z",
                  updated_at: "2022-09-15T16:38:17.000000Z",
                },
                {
                  id: 15396,
                  kit_id: 26,
                  product_id: 41,
                  created_at: "2022-09-15T16:38:17.000000Z",
                  updated_at: "2022-09-15T16:38:17.000000Z",
                },
                {
                  id: 15397,
                  kit_id: 26,
                  product_id: 46,
                  created_at: "2022-09-15T16:38:17.000000Z",
                  updated_at: "2022-09-15T16:38:17.000000Z",
                },
                {
                  id: 15398,
                  kit_id: 26,
                  product_id: 48,
                  created_at: "2022-09-15T16:38:17.000000Z",
                  updated_at: "2022-09-15T16:38:17.000000Z",
                },
                {
                  id: 15399,
                  kit_id: 26,
                  product_id: 50,
                  created_at: "2022-09-15T16:38:17.000000Z",
                  updated_at: "2022-09-15T16:38:17.000000Z",
                },
                {
                  id: 15400,
                  kit_id: 26,
                  product_id: 51,
                  created_at: "2022-09-15T16:38:17.000000Z",
                  updated_at: "2022-09-15T16:38:17.000000Z",
                },
                {
                  id: 15401,
                  kit_id: 26,
                  product_id: 54,
                  created_at: "2022-09-15T16:38:17.000000Z",
                  updated_at: "2022-09-15T16:38:17.000000Z",
                },
                {
                  id: 15402,
                  kit_id: 26,
                  product_id: 61,
                  created_at: "2022-09-15T16:38:17.000000Z",
                  updated_at: "2022-09-15T16:38:17.000000Z",
                },
                {
                  id: 15403,
                  kit_id: 26,
                  product_id: 67,
                  created_at: "2022-09-15T16:38:17.000000Z",
                  updated_at: "2022-09-15T16:38:17.000000Z",
                },
                {
                  id: 15404,
                  kit_id: 26,
                  product_id: 64,
                  created_at: "2022-09-15T16:38:17.000000Z",
                  updated_at: "2022-09-15T16:38:17.000000Z",
                },
                {
                  id: 15405,
                  kit_id: 26,
                  product_id: 89,
                  created_at: "2022-09-15T16:38:17.000000Z",
                  updated_at: "2022-09-15T16:38:17.000000Z",
                },
                {
                  id: 15406,
                  kit_id: 26,
                  product_id: 135,
                  created_at: "2022-09-15T16:38:17.000000Z",
                  updated_at: "2022-09-15T16:38:17.000000Z",
                },
                {
                  id: 15407,
                  kit_id: 26,
                  product_id: 118,
                  created_at: "2022-09-15T16:38:17.000000Z",
                  updated_at: "2022-09-15T16:38:17.000000Z",
                },
                {
                  id: 15408,
                  kit_id: 26,
                  product_id: 169,
                  created_at: "2022-09-15T16:38:17.000000Z",
                  updated_at: "2022-09-15T16:38:17.000000Z",
                },
                {
                  id: 15409,
                  kit_id: 26,
                  product_id: 161,
                  created_at: "2022-09-15T16:38:17.000000Z",
                  updated_at: "2022-09-15T16:38:17.000000Z",
                },
                {
                  id: 15410,
                  kit_id: 26,
                  product_id: 165,
                  created_at: "2022-09-15T16:38:17.000000Z",
                  updated_at: "2022-09-15T16:38:17.000000Z",
                },
                {
                  id: 15411,
                  kit_id: 26,
                  product_id: 178,
                  created_at: "2022-09-15T16:38:17.000000Z",
                  updated_at: "2022-09-15T16:38:17.000000Z",
                },
                {
                  id: 15412,
                  kit_id: 26,
                  product_id: 55,
                  created_at: "2022-09-15T16:38:17.000000Z",
                  updated_at: "2022-09-15T16:38:17.000000Z",
                },
                {
                  id: 15413,
                  kit_id: 26,
                  product_id: 6,
                  created_at: "2022-09-15T16:38:17.000000Z",
                  updated_at: "2022-09-15T16:38:17.000000Z",
                },
                {
                  id: 15414,
                  kit_id: 26,
                  product_id: 189,
                  created_at: "2022-09-15T16:38:17.000000Z",
                  updated_at: "2022-09-15T16:38:17.000000Z",
                },
                {
                  id: 15415,
                  kit_id: 26,
                  product_id: 190,
                  created_at: "2022-09-15T16:38:17.000000Z",
                  updated_at: "2022-09-15T16:38:17.000000Z",
                },
                {
                  id: 15416,
                  kit_id: 26,
                  product_id: 162,
                  created_at: "2022-09-15T16:38:17.000000Z",
                  updated_at: "2022-09-15T16:38:17.000000Z",
                },
                {
                  id: 15417,
                  kit_id: 26,
                  product_id: 4,
                  created_at: "2022-09-15T16:38:17.000000Z",
                  updated_at: "2022-09-15T16:38:17.000000Z",
                },
                {
                  id: 15418,
                  kit_id: 26,
                  product_id: 195,
                  created_at: "2022-09-15T16:38:17.000000Z",
                  updated_at: "2022-09-15T16:38:17.000000Z",
                },
                {
                  id: 15419,
                  kit_id: 26,
                  product_id: 216,
                  created_at: "2022-09-15T16:38:17.000000Z",
                  updated_at: "2022-09-15T16:38:17.000000Z",
                },
              ],
              tipologies: [
                {
                  id: 3794,
                  kit_id: 26,
                  tipology_id: 22,
                  created_at: "2022-09-15T16:38:17.000000Z",
                  updated_at: "2022-09-15T16:38:17.000000Z",
                  tipology: {
                    id: 22,
                    name: "TIPO 05\t- 52,49m\u00b2",
                    description: "Studio com banho e balc\u00e3o.",
                    image:
                      "https://qsuite-buckets.s3.us-west-2.amazonaws.com/tipologies/lipFkfGJZn.jpg",
                    company_id: 4,
                    created_at: "2022-03-16T13:23:16.000000Z",
                    updated_at: "2022-08-19T16:41:02.000000Z",
                    code: "T5P3",
                    color: "#c800ff",
                  },
                },
                {
                  id: 3795,
                  kit_id: 26,
                  tipology_id: 24,
                  created_at: "2022-09-15T16:38:17.000000Z",
                  updated_at: "2022-09-15T16:38:17.000000Z",
                  tipology: {
                    id: 24,
                    name: "TIPO 05\t- 66,37m\u00b2",
                    description: "Studio com banho e terra\u00e7o.",
                    image:
                      "https://qsuite-buckets.s3.us-west-2.amazonaws.com/tipologies/nrmAcKaLMR.jpg",
                    company_id: 4,
                    created_at: "2022-03-16T13:24:14.000000Z",
                    updated_at: "2022-08-19T17:11:15.000000Z",
                    code: "T5P5",
                    color: "#c800ff",
                  },
                },
                {
                  id: 3796,
                  kit_id: 26,
                  tipology_id: 25,
                  created_at: "2022-09-15T16:38:17.000000Z",
                  updated_at: "2022-09-15T16:38:17.000000Z",
                  tipology: {
                    id: 25,
                    name: "TIPO 05\t- 66,43m\u00b2",
                    description: "Studio com banho e terra\u00e7o.",
                    image:
                      "https://qsuite-buckets.s3.us-west-2.amazonaws.com/tipologies/k1p0tOXzf7.jpg",
                    company_id: 4,
                    created_at: "2022-03-16T13:24:41.000000Z",
                    updated_at: "2022-08-19T17:11:52.000000Z",
                    code: "T5P6",
                    color: "#c800ff",
                  },
                },
                {
                  id: 3797,
                  kit_id: 26,
                  tipology_id: 26,
                  created_at: "2022-09-15T16:38:17.000000Z",
                  updated_at: "2022-09-15T16:38:17.000000Z",
                  tipology: {
                    id: 26,
                    name: "TIPO 05\t- 51,44m\u00b2",
                    description: "Studio com banho e balc\u00e3o.",
                    image:
                      "https://qsuite-buckets.s3.us-west-2.amazonaws.com/tipologies/8Vqb4WjPj6.jpg",
                    company_id: 4,
                    created_at: "2022-03-16T13:25:08.000000Z",
                    updated_at: "2022-08-19T17:12:33.000000Z",
                    code: "T5P7",
                    color: "#c800ff",
                  },
                },
                {
                  id: 3798,
                  kit_id: 26,
                  tipology_id: 44,
                  created_at: "2022-09-15T16:38:17.000000Z",
                  updated_at: "2022-09-15T16:38:17.000000Z",
                  tipology: {
                    id: 44,
                    name: "TIPO 05 - 51,50m\u00b2",
                    description: "Studio com banho e balc\u00e3o.",
                    image:
                      "https://qsuite-buckets.s3.us-west-2.amazonaws.com/tipologies/hooYXdqABD.jpg",
                    company_id: 4,
                    created_at: "2022-09-15T16:28:38.000000Z",
                    updated_at: "2022-09-15T16:28:56.000000Z",
                    code: "T5P7*",
                    color: "#c800ff",
                  },
                },
              ],
            },
            {
              id: 29,
              name: "KIT_LIVE",
              description:
                "Kit com m\u00f3veis, arm\u00e1rios, eletrodom\u00e9sticos, ar condicionados, ilumina\u00e7\u00e3o e acess\u00f3rios sanit\u00e1rios.",
              exhibition: "GRADE",
              price: 119670.61,
              category_id: 2,
              gallery_id: 32,
              enterprise_id: 13,
              company_id: 4,
              deleted: 0,
              created_at: "2022-04-07T18:38:48.000000Z",
              updated_at: "2022-09-15T16:38:36.000000Z",
              type: "MAIN",
              decoration_kit_id: 37,
              is_decoration: 0,
              order: 1,
              free_secondary: 1,
              master_kit: null,
              products: [
                {
                  id: 15420,
                  kit_id: 29,
                  product_id: 64,
                  created_at: "2022-09-15T16:38:36.000000Z",
                  updated_at: "2022-09-15T16:38:36.000000Z",
                },
                {
                  id: 15421,
                  kit_id: 29,
                  product_id: 8,
                  created_at: "2022-09-15T16:38:36.000000Z",
                  updated_at: "2022-09-15T16:38:36.000000Z",
                },
                {
                  id: 15422,
                  kit_id: 29,
                  product_id: 135,
                  created_at: "2022-09-15T16:38:36.000000Z",
                  updated_at: "2022-09-15T16:38:36.000000Z",
                },
                {
                  id: 15423,
                  kit_id: 29,
                  product_id: 55,
                  created_at: "2022-09-15T16:38:36.000000Z",
                  updated_at: "2022-09-15T16:38:36.000000Z",
                },
                {
                  id: 15424,
                  kit_id: 29,
                  product_id: 61,
                  created_at: "2022-09-15T16:38:36.000000Z",
                  updated_at: "2022-09-15T16:38:36.000000Z",
                },
                {
                  id: 15425,
                  kit_id: 29,
                  product_id: 169,
                  created_at: "2022-09-15T16:38:36.000000Z",
                  updated_at: "2022-09-15T16:38:36.000000Z",
                },
                {
                  id: 15426,
                  kit_id: 29,
                  product_id: 51,
                  created_at: "2022-09-15T16:38:36.000000Z",
                  updated_at: "2022-09-15T16:38:36.000000Z",
                },
                {
                  id: 15427,
                  kit_id: 29,
                  product_id: 11,
                  created_at: "2022-09-15T16:38:36.000000Z",
                  updated_at: "2022-09-15T16:38:36.000000Z",
                },
                {
                  id: 15428,
                  kit_id: 29,
                  product_id: 49,
                  created_at: "2022-09-15T16:38:36.000000Z",
                  updated_at: "2022-09-15T16:38:36.000000Z",
                },
                {
                  id: 15429,
                  kit_id: 29,
                  product_id: 6,
                  created_at: "2022-09-15T16:38:36.000000Z",
                  updated_at: "2022-09-15T16:38:36.000000Z",
                },
                {
                  id: 15430,
                  kit_id: 29,
                  product_id: 165,
                  created_at: "2022-09-15T16:38:36.000000Z",
                  updated_at: "2022-09-15T16:38:36.000000Z",
                },
                {
                  id: 15431,
                  kit_id: 29,
                  product_id: 67,
                  created_at: "2022-09-15T16:38:36.000000Z",
                  updated_at: "2022-09-15T16:38:36.000000Z",
                },
                {
                  id: 15432,
                  kit_id: 29,
                  product_id: 14,
                  created_at: "2022-09-15T16:38:36.000000Z",
                  updated_at: "2022-09-15T16:38:36.000000Z",
                },
                {
                  id: 15433,
                  kit_id: 29,
                  product_id: 54,
                  created_at: "2022-09-15T16:38:36.000000Z",
                  updated_at: "2022-09-15T16:38:36.000000Z",
                },
                {
                  id: 15434,
                  kit_id: 29,
                  product_id: 89,
                  created_at: "2022-09-15T16:38:36.000000Z",
                  updated_at: "2022-09-15T16:38:36.000000Z",
                },
                {
                  id: 15435,
                  kit_id: 29,
                  product_id: 90,
                  created_at: "2022-09-15T16:38:36.000000Z",
                  updated_at: "2022-09-15T16:38:36.000000Z",
                },
                {
                  id: 15436,
                  kit_id: 29,
                  product_id: 108,
                  created_at: "2022-09-15T16:38:36.000000Z",
                  updated_at: "2022-09-15T16:38:36.000000Z",
                },
                {
                  id: 15437,
                  kit_id: 29,
                  product_id: 21,
                  created_at: "2022-09-15T16:38:36.000000Z",
                  updated_at: "2022-09-15T16:38:36.000000Z",
                },
                {
                  id: 15438,
                  kit_id: 29,
                  product_id: 34,
                  created_at: "2022-09-15T16:38:36.000000Z",
                  updated_at: "2022-09-15T16:38:36.000000Z",
                },
                {
                  id: 15439,
                  kit_id: 29,
                  product_id: 26,
                  created_at: "2022-09-15T16:38:36.000000Z",
                  updated_at: "2022-09-15T16:38:36.000000Z",
                },
                {
                  id: 15440,
                  kit_id: 29,
                  product_id: 33,
                  created_at: "2022-09-15T16:38:36.000000Z",
                  updated_at: "2022-09-15T16:38:36.000000Z",
                },
                {
                  id: 15441,
                  kit_id: 29,
                  product_id: 40,
                  created_at: "2022-09-15T16:38:36.000000Z",
                  updated_at: "2022-09-15T16:38:36.000000Z",
                },
                {
                  id: 15442,
                  kit_id: 29,
                  product_id: 46,
                  created_at: "2022-09-15T16:38:36.000000Z",
                  updated_at: "2022-09-15T16:38:36.000000Z",
                },
                {
                  id: 15443,
                  kit_id: 29,
                  product_id: 36,
                  created_at: "2022-09-15T16:38:36.000000Z",
                  updated_at: "2022-09-15T16:38:36.000000Z",
                },
                {
                  id: 15444,
                  kit_id: 29,
                  product_id: 47,
                  created_at: "2022-09-15T16:38:36.000000Z",
                  updated_at: "2022-09-15T16:38:36.000000Z",
                },
                {
                  id: 15445,
                  kit_id: 29,
                  product_id: 178,
                  created_at: "2022-09-15T16:38:36.000000Z",
                  updated_at: "2022-09-15T16:38:36.000000Z",
                },
                {
                  id: 15446,
                  kit_id: 29,
                  product_id: 17,
                  created_at: "2022-09-15T16:38:36.000000Z",
                  updated_at: "2022-09-15T16:38:36.000000Z",
                },
                {
                  id: 15447,
                  kit_id: 29,
                  product_id: 162,
                  created_at: "2022-09-15T16:38:36.000000Z",
                  updated_at: "2022-09-15T16:38:36.000000Z",
                },
                {
                  id: 15448,
                  kit_id: 29,
                  product_id: 216,
                  created_at: "2022-09-15T16:38:36.000000Z",
                  updated_at: "2022-09-15T16:38:36.000000Z",
                },
                {
                  id: 15449,
                  kit_id: 29,
                  product_id: 215,
                  created_at: "2022-09-15T16:38:36.000000Z",
                  updated_at: "2022-09-15T16:38:36.000000Z",
                },
                {
                  id: 15450,
                  kit_id: 29,
                  product_id: 189,
                  created_at: "2022-09-15T16:38:36.000000Z",
                  updated_at: "2022-09-15T16:38:36.000000Z",
                },
                {
                  id: 15451,
                  kit_id: 29,
                  product_id: 190,
                  created_at: "2022-09-15T16:38:36.000000Z",
                  updated_at: "2022-09-15T16:38:36.000000Z",
                },
                {
                  id: 15452,
                  kit_id: 29,
                  product_id: 24,
                  created_at: "2022-09-15T16:38:36.000000Z",
                  updated_at: "2022-09-15T16:38:36.000000Z",
                },
              ],
              tipologies: [
                {
                  id: 3799,
                  kit_id: 29,
                  tipology_id: 22,
                  created_at: "2022-09-15T16:38:36.000000Z",
                  updated_at: "2022-09-15T16:38:36.000000Z",
                  tipology: {
                    id: 22,
                    name: "TIPO 05\t- 52,49m\u00b2",
                    description: "Studio com banho e balc\u00e3o.",
                    image:
                      "https://qsuite-buckets.s3.us-west-2.amazonaws.com/tipologies/lipFkfGJZn.jpg",
                    company_id: 4,
                    created_at: "2022-03-16T13:23:16.000000Z",
                    updated_at: "2022-08-19T16:41:02.000000Z",
                    code: "T5P3",
                    color: "#c800ff",
                  },
                },
                {
                  id: 3800,
                  kit_id: 29,
                  tipology_id: 24,
                  created_at: "2022-09-15T16:38:36.000000Z",
                  updated_at: "2022-09-15T16:38:36.000000Z",
                  tipology: {
                    id: 24,
                    name: "TIPO 05\t- 66,37m\u00b2",
                    description: "Studio com banho e terra\u00e7o.",
                    image:
                      "https://qsuite-buckets.s3.us-west-2.amazonaws.com/tipologies/nrmAcKaLMR.jpg",
                    company_id: 4,
                    created_at: "2022-03-16T13:24:14.000000Z",
                    updated_at: "2022-08-19T17:11:15.000000Z",
                    code: "T5P5",
                    color: "#c800ff",
                  },
                },
                {
                  id: 3801,
                  kit_id: 29,
                  tipology_id: 25,
                  created_at: "2022-09-15T16:38:36.000000Z",
                  updated_at: "2022-09-15T16:38:36.000000Z",
                  tipology: {
                    id: 25,
                    name: "TIPO 05\t- 66,43m\u00b2",
                    description: "Studio com banho e terra\u00e7o.",
                    image:
                      "https://qsuite-buckets.s3.us-west-2.amazonaws.com/tipologies/k1p0tOXzf7.jpg",
                    company_id: 4,
                    created_at: "2022-03-16T13:24:41.000000Z",
                    updated_at: "2022-08-19T17:11:52.000000Z",
                    code: "T5P6",
                    color: "#c800ff",
                  },
                },
                {
                  id: 3802,
                  kit_id: 29,
                  tipology_id: 26,
                  created_at: "2022-09-15T16:38:36.000000Z",
                  updated_at: "2022-09-15T16:38:36.000000Z",
                  tipology: {
                    id: 26,
                    name: "TIPO 05\t- 51,44m\u00b2",
                    description: "Studio com banho e balc\u00e3o.",
                    image:
                      "https://qsuite-buckets.s3.us-west-2.amazonaws.com/tipologies/8Vqb4WjPj6.jpg",
                    company_id: 4,
                    created_at: "2022-03-16T13:25:08.000000Z",
                    updated_at: "2022-08-19T17:12:33.000000Z",
                    code: "T5P7",
                    color: "#c800ff",
                  },
                },
                {
                  id: 3803,
                  kit_id: 29,
                  tipology_id: 44,
                  created_at: "2022-09-15T16:38:36.000000Z",
                  updated_at: "2022-09-15T16:38:36.000000Z",
                  tipology: {
                    id: 44,
                    name: "TIPO 05 - 51,50m\u00b2",
                    description: "Studio com banho e balc\u00e3o.",
                    image:
                      "https://qsuite-buckets.s3.us-west-2.amazonaws.com/tipologies/hooYXdqABD.jpg",
                    company_id: 4,
                    created_at: "2022-09-15T16:28:38.000000Z",
                    updated_at: "2022-09-15T16:28:56.000000Z",
                    code: "T5P7*",
                    color: "#c800ff",
                  },
                },
              ],
            },
            {
              id: 36,
              name: "COMBO_DECORA\u00c7\u00c3O",
              description:
                "Combo com decora\u00e7\u00f5es para todo o apartamento.",
              exhibition: "GRADE",
              price: 4484.7,
              category_id: 1,
              gallery_id: 1,
              enterprise_id: 13,
              company_id: 4,
              deleted: 0,
              created_at: "2022-04-07T19:49:24.000000Z",
              updated_at: "2022-09-15T16:38:58.000000Z",
              type: "SECONDARY",
              decoration_kit_id: 36,
              is_decoration: 1,
              order: 7,
              free_secondary: 0,
              master_kit: null,
              products: [
                {
                  id: 15453,
                  kit_id: 36,
                  product_id: 70,
                  created_at: "2022-09-15T16:38:58.000000Z",
                  updated_at: "2022-09-15T16:38:58.000000Z",
                },
                {
                  id: 15454,
                  kit_id: 36,
                  product_id: 71,
                  created_at: "2022-09-15T16:38:58.000000Z",
                  updated_at: "2022-09-15T16:38:58.000000Z",
                },
                {
                  id: 15455,
                  kit_id: 36,
                  product_id: 72,
                  created_at: "2022-09-15T16:38:58.000000Z",
                  updated_at: "2022-09-15T16:38:58.000000Z",
                },
                {
                  id: 15456,
                  kit_id: 36,
                  product_id: 73,
                  created_at: "2022-09-15T16:38:58.000000Z",
                  updated_at: "2022-09-15T16:38:58.000000Z",
                },
                {
                  id: 15457,
                  kit_id: 36,
                  product_id: 75,
                  created_at: "2022-09-15T16:38:58.000000Z",
                  updated_at: "2022-09-15T16:38:58.000000Z",
                },
                {
                  id: 15458,
                  kit_id: 36,
                  product_id: 84,
                  created_at: "2022-09-15T16:38:58.000000Z",
                  updated_at: "2022-09-15T16:38:58.000000Z",
                },
                {
                  id: 15459,
                  kit_id: 36,
                  product_id: 80,
                  created_at: "2022-09-15T16:38:58.000000Z",
                  updated_at: "2022-09-15T16:38:58.000000Z",
                },
                {
                  id: 15460,
                  kit_id: 36,
                  product_id: 86,
                  created_at: "2022-09-15T16:38:58.000000Z",
                  updated_at: "2022-09-15T16:38:58.000000Z",
                },
                {
                  id: 15461,
                  kit_id: 36,
                  product_id: 92,
                  created_at: "2022-09-15T16:38:58.000000Z",
                  updated_at: "2022-09-15T16:38:58.000000Z",
                },
              ],
              tipologies: [
                {
                  id: 3804,
                  kit_id: 36,
                  tipology_id: 1,
                  created_at: "2022-09-15T16:38:58.000000Z",
                  updated_at: "2022-09-15T16:38:58.000000Z",
                  tipology: {
                    id: 1,
                    name: "TIPO 01 - 99,95m\u00b2",
                    description:
                      "Living, banho social, home office, su\u00edte master e terra\u00e7o.",
                    image:
                      "https://qsuite-buckets.s3.us-west-2.amazonaws.com/tipologies/Vs2yIzcnRO.jpg",
                    company_id: 4,
                    created_at: "2022-03-10T14:20:12.000000Z",
                    updated_at: "2022-08-19T16:43:41.000000Z",
                    code: "T1P1",
                    color: "#fbff00",
                  },
                },
                {
                  id: 3805,
                  kit_id: 36,
                  tipology_id: 2,
                  created_at: "2022-09-15T16:38:58.000000Z",
                  updated_at: "2022-09-15T16:38:58.000000Z",
                  tipology: {
                    id: 2,
                    name: "TIPO 01 - 92,75m\u00b2",
                    description:
                      "Living, banho social, home office, su\u00edte master e terra\u00e7o.",
                    image:
                      "https://qsuite-buckets.s3.us-west-2.amazonaws.com/tipologies/0ZZNoFGJAS.jpg",
                    company_id: 4,
                    created_at: "2022-03-10T14:44:40.000000Z",
                    updated_at: "2022-08-19T16:44:04.000000Z",
                    code: "T1P2",
                    color: "#fbff00",
                  },
                },
                {
                  id: 3806,
                  kit_id: 36,
                  tipology_id: 3,
                  created_at: "2022-09-15T16:38:58.000000Z",
                  updated_at: "2022-09-15T16:38:58.000000Z",
                  tipology: {
                    id: 3,
                    name: "TIPO 01 - 69,61m\u00b2",
                    description:
                      "Living, banho social, home office, su\u00edte master e terra\u00e7o.",
                    image:
                      "https://qsuite-buckets.s3.us-west-2.amazonaws.com/tipologies/wUqabxozY3.jpg",
                    company_id: 4,
                    created_at: "2022-03-11T00:16:01.000000Z",
                    updated_at: "2022-08-19T16:44:53.000000Z",
                    code: "T1P3",
                    color: "#fbff00",
                  },
                },
                {
                  id: 3807,
                  kit_id: 36,
                  tipology_id: 4,
                  created_at: "2022-09-15T16:38:58.000000Z",
                  updated_at: "2022-09-15T16:38:58.000000Z",
                  tipology: {
                    id: 4,
                    name: "TIPO 01 - 55,97m\u00b2",
                    description:
                      "Living, banho social, home office, su\u00edte master e laje t\u00e9cnica.",
                    image:
                      "https://qsuite-buckets.s3.us-west-2.amazonaws.com/tipologies/FaQEhIpP9Y.jpg",
                    company_id: 4,
                    created_at: "2022-03-11T00:16:21.000000Z",
                    updated_at: "2022-08-19T16:45:26.000000Z",
                    code: "T1P4",
                    color: "#fbff00",
                  },
                },
                {
                  id: 3808,
                  kit_id: 36,
                  tipology_id: 5,
                  created_at: "2022-09-15T16:38:58.000000Z",
                  updated_at: "2022-09-15T16:38:58.000000Z",
                  tipology: {
                    id: 5,
                    name: "TIPO 02\t- 72,08 m\u00b2",
                    description:
                      "Living, lavabo, home office com banho, su\u00edte master e laje t\u00e9cnica.",
                    image:
                      "https://qsuite-buckets.s3.us-west-2.amazonaws.com/tipologies/h5zs6ZMt0U.jpg",
                    company_id: 4,
                    created_at: "2022-03-16T13:14:07.000000Z",
                    updated_at: "2022-08-19T16:47:09.000000Z",
                    code: "T2P1",
                    color: "#0084ff",
                  },
                },
                {
                  id: 3809,
                  kit_id: 36,
                  tipology_id: 6,
                  created_at: "2022-09-15T16:38:58.000000Z",
                  updated_at: "2022-09-15T16:38:58.000000Z",
                  tipology: {
                    id: 6,
                    name: "TIPO 02 - 149,88m\u00b2",
                    description:
                      "Living, lavabo, su\u00edte, su\u00edte master e terra\u00e7o.",
                    image:
                      "https://qsuite-buckets.s3.us-west-2.amazonaws.com/tipologies/OYQMiVd7kz.jpg",
                    company_id: 4,
                    created_at: "2022-03-16T13:14:46.000000Z",
                    updated_at: "2022-08-19T16:58:07.000000Z",
                    code: "T2P2",
                    color: "#0084ff",
                  },
                },
                {
                  id: 3810,
                  kit_id: 36,
                  tipology_id: 7,
                  created_at: "2022-09-15T16:38:58.000000Z",
                  updated_at: "2022-09-15T16:38:58.000000Z",
                  tipology: {
                    id: 7,
                    name: "TIPO 02\t- 80,91m\u00b2",
                    description:
                      "Living, lavabo, su\u00edte, su\u00edte master e terra\u00e7o.",
                    image:
                      "https://qsuite-buckets.s3.us-west-2.amazonaws.com/tipologies/7p8WztbloW.jpg",
                    company_id: 4,
                    created_at: "2022-03-16T13:15:25.000000Z",
                    updated_at: "2022-08-19T16:57:46.000000Z",
                    code: "T2P3",
                    color: "#0084ff",
                  },
                },
                {
                  id: 3811,
                  kit_id: 36,
                  tipology_id: 8,
                  created_at: "2022-09-15T16:38:58.000000Z",
                  updated_at: "2022-09-15T16:38:58.000000Z",
                  tipology: {
                    id: 8,
                    name: "TIPO 02\t- 73,06m\u00b2",
                    description:
                      "Living, lavabo, su\u00edte, su\u00edte master e laje t\u00e9cnica.",
                    image:
                      "https://qsuite-buckets.s3.us-west-2.amazonaws.com/tipologies/rdCR2cVx6d.jpg",
                    company_id: 4,
                    created_at: "2022-03-16T13:15:57.000000Z",
                    updated_at: "2022-08-19T16:59:11.000000Z",
                    code: "T2P4",
                    color: "#0084ff",
                  },
                },
                {
                  id: 3812,
                  kit_id: 36,
                  tipology_id: 9,
                  created_at: "2022-09-15T16:38:58.000000Z",
                  updated_at: "2022-09-15T16:38:58.000000Z",
                  tipology: {
                    id: 9,
                    name: "TIPO 02\t- 77,13m\u00b2",
                    description:
                      "Living, lavabo, home office com banho, su\u00edte master e terra\u00e7o.",
                    image:
                      "https://qsuite-buckets.s3.us-west-2.amazonaws.com/tipologies/lifNnFx33o.jpg",
                    company_id: 4,
                    created_at: "2022-03-16T13:16:27.000000Z",
                    updated_at: "2022-08-19T16:59:48.000000Z",
                    code: "T2P5",
                    color: "#0084ff",
                  },
                },
                {
                  id: 3813,
                  kit_id: 36,
                  tipology_id: 10,
                  created_at: "2022-09-15T16:38:58.000000Z",
                  updated_at: "2022-09-15T16:38:58.000000Z",
                  tipology: {
                    id: 10,
                    name: "TIPO 02\t- 77,11m\u00b2",
                    description:
                      "Living, lavabo, home office com banho, su\u00edte master e terra\u00e7o.",
                    image:
                      "https://qsuite-buckets.s3.us-west-2.amazonaws.com/tipologies/INcyJuNCYJ.jpg",
                    company_id: 4,
                    created_at: "2022-03-16T13:16:56.000000Z",
                    updated_at: "2022-08-19T17:00:12.000000Z",
                    code: "T2P6",
                    color: "#0084ff",
                  },
                },
                {
                  id: 3814,
                  kit_id: 36,
                  tipology_id: 11,
                  created_at: "2022-09-15T16:38:58.000000Z",
                  updated_at: "2022-09-15T16:38:58.000000Z",
                  tipology: {
                    id: 11,
                    name: "TIPO 02\t- 66,95m\u00b2",
                    description:
                      "Living, lavabo, home office com banho, su\u00edte master e terra\u00e7o.",
                    image:
                      "https://qsuite-buckets.s3.us-west-2.amazonaws.com/tipologies/VZGEYkqeCH.jpg",
                    company_id: 4,
                    created_at: "2022-03-16T13:17:41.000000Z",
                    updated_at: "2022-08-19T17:00:35.000000Z",
                    code: "T2P7",
                    color: "#0084ff",
                  },
                },
                {
                  id: 3815,
                  kit_id: 36,
                  tipology_id: 12,
                  created_at: "2022-09-15T16:38:58.000000Z",
                  updated_at: "2022-09-15T16:38:58.000000Z",
                  tipology: {
                    id: 12,
                    name: "TIPO 02\t- 66,95m\u00b2",
                    description:
                      "Living, lavabo, home office com banho, su\u00edte master e terra\u00e7o.",
                    image:
                      "https://qsuite-buckets.s3.us-west-2.amazonaws.com/tipologies/HISWgMDtvT.jpg",
                    company_id: 4,
                    created_at: "2022-03-16T13:18:18.000000Z",
                    updated_at: "2022-08-19T17:01:01.000000Z",
                    code: "T2P8",
                    color: "#0084ff",
                  },
                },
                {
                  id: 3816,
                  kit_id: 36,
                  tipology_id: 13,
                  created_at: "2022-09-15T16:38:58.000000Z",
                  updated_at: "2022-09-15T16:38:58.000000Z",
                  tipology: {
                    id: 13,
                    name: "TIPO 03\t- 80,39m\u00b2",
                    description:
                      "Living, lavabo, su\u00edte, su\u00edte master e balc\u00e3o.",
                    image:
                      "https://qsuite-buckets.s3.us-west-2.amazonaws.com/tipologies/gsxbopSSHs.jpg",
                    company_id: 4,
                    created_at: "2022-03-16T13:18:45.000000Z",
                    updated_at: "2022-08-19T17:01:50.000000Z",
                    code: "T3P1",
                    color: "#ffa200",
                  },
                },
                {
                  id: 3817,
                  kit_id: 36,
                  tipology_id: 14,
                  created_at: "2022-09-15T16:38:58.000000Z",
                  updated_at: "2022-09-15T16:38:58.000000Z",
                  tipology: {
                    id: 14,
                    name: "TIPO 03\t- 75,97m\u00b2",
                    description:
                      "Living, lavabo, su\u00edte, su\u00edte master e varanda.",
                    image:
                      "https://qsuite-buckets.s3.us-west-2.amazonaws.com/tipologies/vmxUEHaRAQ.jpg",
                    company_id: 4,
                    created_at: "2022-03-16T13:19:20.000000Z",
                    updated_at: "2022-08-19T17:04:12.000000Z",
                    code: "T3P2",
                    color: "#ffa200",
                  },
                },
                {
                  id: 3818,
                  kit_id: 36,
                  tipology_id: 15,
                  created_at: "2022-09-15T16:38:58.000000Z",
                  updated_at: "2022-09-15T16:38:58.000000Z",
                  tipology: {
                    id: 15,
                    name: "TIPO 03\t- 75,97m\u00b2",
                    description:
                      "Living, lavabo, su\u00edte, su\u00edte master e varanda.",
                    image:
                      "https://qsuite-buckets.s3.us-west-2.amazonaws.com/tipologies/yhulEMHReJ.jpg",
                    company_id: 4,
                    created_at: "2022-03-16T13:19:33.000000Z",
                    updated_at: "2022-03-16T13:19:33.000000Z",
                    code: "T3P2",
                    color: null,
                  },
                },
                {
                  id: 3819,
                  kit_id: 36,
                  tipology_id: 16,
                  created_at: "2022-09-15T16:38:58.000000Z",
                  updated_at: "2022-09-15T16:38:58.000000Z",
                  tipology: {
                    id: 16,
                    name: "TIPO 03\t- 90,35m\u00b2",
                    description:
                      "Living, lavabo, su\u00edte, su\u00edte master e varanda.",
                    image:
                      "https://qsuite-buckets.s3.us-west-2.amazonaws.com/tipologies/obVtY1Kzfp.jpg",
                    company_id: 4,
                    created_at: "2022-03-16T13:20:01.000000Z",
                    updated_at: "2022-08-19T16:42:21.000000Z",
                    code: "T3P3",
                    color: "#ffa200",
                  },
                },
                {
                  id: 3820,
                  kit_id: 36,
                  tipology_id: 17,
                  created_at: "2022-09-15T16:38:58.000000Z",
                  updated_at: "2022-09-15T16:38:58.000000Z",
                  tipology: {
                    id: 17,
                    name: "TIPO 03\t- 75,96m\u00b2",
                    description:
                      "Living, lavabo, su\u00edte, su\u00edte master e varanda.",
                    image:
                      "https://qsuite-buckets.s3.us-west-2.amazonaws.com/tipologies/XTGTB4n3Sm.jpg",
                    company_id: 4,
                    created_at: "2022-03-16T13:20:28.000000Z",
                    updated_at: "2022-08-19T17:06:38.000000Z",
                    code: "T3P4",
                    color: "#ffa200",
                  },
                },
                {
                  id: 3821,
                  kit_id: 36,
                  tipology_id: 18,
                  created_at: "2022-09-15T16:38:58.000000Z",
                  updated_at: "2022-09-15T16:38:58.000000Z",
                  tipology: {
                    id: 18,
                    name: "TIPO 03 - 80,76m\u00b2",
                    description:
                      "Living, lavabo, su\u00edte, su\u00edte master e varanda.",
                    image:
                      "https://qsuite-buckets.s3.us-west-2.amazonaws.com/tipologies/4sz7SO65z0.jpg",
                    company_id: 4,
                    created_at: "2022-03-16T13:21:05.000000Z",
                    updated_at: "2022-08-19T17:07:08.000000Z",
                    code: "T3P5",
                    color: "#ffa200",
                  },
                },
                {
                  id: 3822,
                  kit_id: 36,
                  tipology_id: 19,
                  created_at: "2022-09-15T16:38:58.000000Z",
                  updated_at: "2022-09-15T16:38:58.000000Z",
                  tipology: {
                    id: 19,
                    name: "TIPO 04\t- 70,68m\u00b2",
                    description:
                      "Living, banho social, home office, su\u00edte master e balc\u00e3o.",
                    image:
                      "https://qsuite-buckets.s3.us-west-2.amazonaws.com/tipologies/AJbYiflLt0.jpg",
                    company_id: 4,
                    created_at: "2022-03-16T13:21:33.000000Z",
                    updated_at: "2022-08-19T17:07:54.000000Z",
                    code: "T4P1",
                    color: "#ff00ea",
                  },
                },
                {
                  id: 3823,
                  kit_id: 36,
                  tipology_id: 20,
                  created_at: "2022-09-15T16:38:58.000000Z",
                  updated_at: "2022-09-15T16:38:58.000000Z",
                  tipology: {
                    id: 20,
                    name: "TIPO 05\t- 72,08m\u00b2",
                    description: "Studio com banho e terra\u00e7o.",
                    image:
                      "https://qsuite-buckets.s3.us-west-2.amazonaws.com/tipologies/dO0KyjaRjh.jpg",
                    company_id: 4,
                    created_at: "2022-03-16T13:22:00.000000Z",
                    updated_at: "2022-08-19T17:08:25.000000Z",
                    code: "T5P1",
                    color: "#c800ff",
                  },
                },
                {
                  id: 3824,
                  kit_id: 36,
                  tipology_id: 21,
                  created_at: "2022-09-15T16:38:58.000000Z",
                  updated_at: "2022-09-15T16:38:58.000000Z",
                  tipology: {
                    id: 21,
                    name: "TIPO 05\t- 47,01m\u00b2",
                    description: "Studio com banho e balc\u00e3o.",
                    image:
                      "https://qsuite-buckets.s3.us-west-2.amazonaws.com/tipologies/FkdjpB3Had.jpg",
                    company_id: 4,
                    created_at: "2022-03-16T13:22:45.000000Z",
                    updated_at: "2022-08-19T17:08:59.000000Z",
                    code: "T5P2",
                    color: "#c800ff",
                  },
                },
                {
                  id: 3825,
                  kit_id: 36,
                  tipology_id: 22,
                  created_at: "2022-09-15T16:38:58.000000Z",
                  updated_at: "2022-09-15T16:38:58.000000Z",
                  tipology: {
                    id: 22,
                    name: "TIPO 05\t- 52,49m\u00b2",
                    description: "Studio com banho e balc\u00e3o.",
                    image:
                      "https://qsuite-buckets.s3.us-west-2.amazonaws.com/tipologies/lipFkfGJZn.jpg",
                    company_id: 4,
                    created_at: "2022-03-16T13:23:16.000000Z",
                    updated_at: "2022-08-19T16:41:02.000000Z",
                    code: "T5P3",
                    color: "#c800ff",
                  },
                },
                {
                  id: 3826,
                  kit_id: 36,
                  tipology_id: 23,
                  created_at: "2022-09-15T16:38:58.000000Z",
                  updated_at: "2022-09-15T16:38:58.000000Z",
                  tipology: {
                    id: 23,
                    name: "TIPO 05\t- 60,71m\u00b2",
                    description: "Studio com banho e laje t\u00e9cnica.",
                    image:
                      "https://qsuite-buckets.s3.us-west-2.amazonaws.com/tipologies/Hx7LLObb0s.jpg",
                    company_id: 4,
                    created_at: "2022-03-16T13:23:46.000000Z",
                    updated_at: "2022-08-19T17:10:36.000000Z",
                    code: "T5P4",
                    color: "#c800ff",
                  },
                },
                {
                  id: 3827,
                  kit_id: 36,
                  tipology_id: 24,
                  created_at: "2022-09-15T16:38:58.000000Z",
                  updated_at: "2022-09-15T16:38:58.000000Z",
                  tipology: {
                    id: 24,
                    name: "TIPO 05\t- 66,37m\u00b2",
                    description: "Studio com banho e terra\u00e7o.",
                    image:
                      "https://qsuite-buckets.s3.us-west-2.amazonaws.com/tipologies/nrmAcKaLMR.jpg",
                    company_id: 4,
                    created_at: "2022-03-16T13:24:14.000000Z",
                    updated_at: "2022-08-19T17:11:15.000000Z",
                    code: "T5P5",
                    color: "#c800ff",
                  },
                },
                {
                  id: 3828,
                  kit_id: 36,
                  tipology_id: 25,
                  created_at: "2022-09-15T16:38:58.000000Z",
                  updated_at: "2022-09-15T16:38:58.000000Z",
                  tipology: {
                    id: 25,
                    name: "TIPO 05\t- 66,43m\u00b2",
                    description: "Studio com banho e terra\u00e7o.",
                    image:
                      "https://qsuite-buckets.s3.us-west-2.amazonaws.com/tipologies/k1p0tOXzf7.jpg",
                    company_id: 4,
                    created_at: "2022-03-16T13:24:41.000000Z",
                    updated_at: "2022-08-19T17:11:52.000000Z",
                    code: "T5P6",
                    color: "#c800ff",
                  },
                },
                {
                  id: 3829,
                  kit_id: 36,
                  tipology_id: 26,
                  created_at: "2022-09-15T16:38:58.000000Z",
                  updated_at: "2022-09-15T16:38:58.000000Z",
                  tipology: {
                    id: 26,
                    name: "TIPO 05\t- 51,44m\u00b2",
                    description: "Studio com banho e balc\u00e3o.",
                    image:
                      "https://qsuite-buckets.s3.us-west-2.amazonaws.com/tipologies/8Vqb4WjPj6.jpg",
                    company_id: 4,
                    created_at: "2022-03-16T13:25:08.000000Z",
                    updated_at: "2022-08-19T17:12:33.000000Z",
                    code: "T5P7",
                    color: "#c800ff",
                  },
                },
                {
                  id: 3830,
                  kit_id: 36,
                  tipology_id: 27,
                  created_at: "2022-09-15T16:38:58.000000Z",
                  updated_at: "2022-09-15T16:38:58.000000Z",
                  tipology: {
                    id: 27,
                    name: "TIPO 05\t- 47,01m\u00b2",
                    description: "Studio com banho e balc\u00e3o.",
                    image:
                      "https://qsuite-buckets.s3.us-west-2.amazonaws.com/tipologies/976rjj2xVO.jpg",
                    company_id: 4,
                    created_at: "2022-03-16T13:27:07.000000Z",
                    updated_at: "2022-08-19T17:13:18.000000Z",
                    code: "T5P8",
                    color: "#c800ff",
                  },
                },
                {
                  id: 3831,
                  kit_id: 36,
                  tipology_id: 28,
                  created_at: "2022-09-15T16:38:58.000000Z",
                  updated_at: "2022-09-15T16:38:58.000000Z",
                  tipology: {
                    id: 28,
                    name: "TIPO 05\t- 37,89m\u00b2",
                    description: "Studio com banho e laje t\u00e9cnica.",
                    image:
                      "https://qsuite-buckets.s3.us-west-2.amazonaws.com/tipologies/9P86YXGDQQ.jpg",
                    company_id: 4,
                    created_at: "2022-03-16T13:28:00.000000Z",
                    updated_at: "2022-08-19T17:13:38.000000Z",
                    code: "T5P9",
                    color: "#c800ff",
                  },
                },
                {
                  id: 3832,
                  kit_id: 36,
                  tipology_id: 29,
                  created_at: "2022-09-15T16:38:58.000000Z",
                  updated_at: "2022-09-15T16:38:58.000000Z",
                  tipology: {
                    id: 29,
                    name: "TIPO 06\t- 97,73m\u00b2",
                    description:
                      "Living, lavabo, su\u00edte, su\u00edte master e terra\u00e7o.",
                    image:
                      "https://qsuite-buckets.s3.us-west-2.amazonaws.com/tipologies/nKQC982QoT.jpg",
                    company_id: 4,
                    created_at: "2022-03-16T13:28:27.000000Z",
                    updated_at: "2022-08-19T17:14:16.000000Z",
                    code: "T6P1",
                    color: "#ff0000",
                  },
                },
                {
                  id: 3833,
                  kit_id: 36,
                  tipology_id: 35,
                  created_at: "2022-09-15T16:38:58.000000Z",
                  updated_at: "2022-09-15T16:38:58.000000Z",
                  tipology: {
                    id: 35,
                    name: "TIPO 06\t- 69,81m\u00b2",
                    description:
                      "Living, lavabo, su\u00edte, su\u00edte master e laje t\u00e9cnica.",
                    image:
                      "https://qsuite-buckets.s3.us-west-2.amazonaws.com/tipologies/5iKD9tcHq7.jpg",
                    company_id: 4,
                    created_at: "2022-03-16T13:31:33.000000Z",
                    updated_at: "2022-08-19T17:16:58.000000Z",
                    code: "T6P7",
                    color: "#ff0000",
                  },
                },
                {
                  id: 3834,
                  kit_id: 36,
                  tipology_id: 34,
                  created_at: "2022-09-15T16:38:58.000000Z",
                  updated_at: "2022-09-15T16:38:58.000000Z",
                  tipology: {
                    id: 34,
                    name: "TIPO 06\t- 69,81m\u00b2",
                    description:
                      "Living, lavabo, su\u00edte, su\u00edte master e laje t\u00e9cnica.",
                    image:
                      "https://qsuite-buckets.s3.us-west-2.amazonaws.com/tipologies/bBh0RWjH3a.jpg",
                    company_id: 4,
                    created_at: "2022-03-16T13:31:04.000000Z",
                    updated_at: "2022-08-19T17:16:22.000000Z",
                    code: "T6P6",
                    color: "#ff0000",
                  },
                },
                {
                  id: 3835,
                  kit_id: 36,
                  tipology_id: 33,
                  created_at: "2022-09-15T16:38:58.000000Z",
                  updated_at: "2022-09-15T16:38:58.000000Z",
                  tipology: {
                    id: 33,
                    name: "TIPO 06\t- 71,89m\u00b2",
                    description:
                      "Living, lavabo, su\u00edte, su\u00edte master e balc\u00e3o.",
                    image:
                      "https://qsuite-buckets.s3.us-west-2.amazonaws.com/tipologies/us7WEVxfNc.jpg",
                    company_id: 4,
                    created_at: "2022-03-16T13:30:30.000000Z",
                    updated_at: "2022-08-19T17:15:56.000000Z",
                    code: "T6P5",
                    color: "#ff0000",
                  },
                },
                {
                  id: 3836,
                  kit_id: 36,
                  tipology_id: 32,
                  created_at: "2022-09-15T16:38:58.000000Z",
                  updated_at: "2022-09-15T16:38:58.000000Z",
                  tipology: {
                    id: 32,
                    name: "TIPO 06\t- 97,73m\u00b2",
                    description:
                      "Living, lavabo, su\u00edte, su\u00edte master e terra\u00e7o.",
                    image:
                      "https://qsuite-buckets.s3.us-west-2.amazonaws.com/tipologies/7zM76EBH4X.jpg",
                    company_id: 4,
                    created_at: "2022-03-16T13:29:54.000000Z",
                    updated_at: "2022-08-19T17:15:32.000000Z",
                    code: "T6P4",
                    color: "#ff0000",
                  },
                },
                {
                  id: 3837,
                  kit_id: 36,
                  tipology_id: 31,
                  created_at: "2022-09-15T16:38:58.000000Z",
                  updated_at: "2022-09-15T16:38:58.000000Z",
                  tipology: {
                    id: 31,
                    name: "TIPO 06\t- 99,99m\u00b2",
                    description:
                      "Living, lavabo, su\u00edte, su\u00edte master e terra\u00e7o.",
                    image:
                      "https://qsuite-buckets.s3.us-west-2.amazonaws.com/tipologies/S14y16YIim.jpg",
                    company_id: 4,
                    created_at: "2022-03-16T13:29:24.000000Z",
                    updated_at: "2022-08-19T17:15:06.000000Z",
                    code: "T6P3",
                    color: "#ff0000",
                  },
                },
                {
                  id: 3838,
                  kit_id: 36,
                  tipology_id: 30,
                  created_at: "2022-09-15T16:38:58.000000Z",
                  updated_at: "2022-09-15T16:38:58.000000Z",
                  tipology: {
                    id: 30,
                    name: "TIPO 06\t- 99,99m\u00b2",
                    description:
                      "Living, lavabo, su\u00edte, su\u00edte master e terra\u00e7o.",
                    image:
                      "https://qsuite-buckets.s3.us-west-2.amazonaws.com/tipologies/dWs4YcNgJJ.jpg",
                    company_id: 4,
                    created_at: "2022-03-16T13:28:54.000000Z",
                    updated_at: "2022-08-19T17:14:42.000000Z",
                    code: "T6P2",
                    color: "#ff0000",
                  },
                },
                {
                  id: 3839,
                  kit_id: 36,
                  tipology_id: 36,
                  created_at: "2022-09-15T16:38:58.000000Z",
                  updated_at: "2022-09-15T16:38:58.000000Z",
                  tipology: {
                    id: 36,
                    name: "TIPO 06\t- 71,89m\u00b2",
                    description:
                      "Living, lavabo, su\u00edte, su\u00edte master e laje t\u00e9cnica.",
                    image:
                      "https://qsuite-buckets.s3.us-west-2.amazonaws.com/tipologies/fX5TviLoMA.jpg",
                    company_id: 4,
                    created_at: "2022-03-16T13:32:17.000000Z",
                    updated_at: "2022-08-19T17:17:31.000000Z",
                    code: "T6P8",
                    color: "#ff0000",
                  },
                },
                {
                  id: 3840,
                  kit_id: 36,
                  tipology_id: 37,
                  created_at: "2022-09-15T16:38:58.000000Z",
                  updated_at: "2022-09-15T16:38:58.000000Z",
                  tipology: {
                    id: 37,
                    name: "TIPO 07\t- 71,94m\u00b2",
                    description:
                      "Living, banho social, home office, su\u00edte master e terra\u00e7o.",
                    image:
                      "https://qsuite-buckets.s3.us-west-2.amazonaws.com/tipologies/yG3ZdpRC3M.jpg",
                    company_id: 4,
                    created_at: "2022-03-16T13:33:20.000000Z",
                    updated_at: "2022-08-19T17:18:17.000000Z",
                    code: "T7P1",
                    color: "#0cad00",
                  },
                },
                {
                  id: 3841,
                  kit_id: 36,
                  tipology_id: 38,
                  created_at: "2022-09-15T16:38:58.000000Z",
                  updated_at: "2022-09-15T16:38:58.000000Z",
                  tipology: {
                    id: 38,
                    name: "TIPO 07\t- 71,94m\u00b2",
                    description:
                      "Living, banho social, home office, su\u00edte master e terra\u00e7o.",
                    image:
                      "https://qsuite-buckets.s3.us-west-2.amazonaws.com/tipologies/oVNwRRYe7U.jpg",
                    company_id: 4,
                    created_at: "2022-03-16T13:34:08.000000Z",
                    updated_at: "2022-08-19T17:19:00.000000Z",
                    code: "T7P2",
                    color: "#0cad00",
                  },
                },
                {
                  id: 3842,
                  kit_id: 36,
                  tipology_id: 39,
                  created_at: "2022-09-15T16:38:58.000000Z",
                  updated_at: "2022-09-15T16:38:58.000000Z",
                  tipology: {
                    id: 39,
                    name: "TIPO 07\t- 52,90m\u00b2",
                    description:
                      "Living, banho social, home office, su\u00edte master e balc\u00e3o.",
                    image:
                      "https://qsuite-buckets.s3.us-west-2.amazonaws.com/tipologies/Qrlx3FAVtd.jpg",
                    company_id: 4,
                    created_at: "2022-03-16T13:34:38.000000Z",
                    updated_at: "2022-08-19T17:19:52.000000Z",
                    code: "T7P3",
                    color: "#0cad00",
                  },
                },
                {
                  id: 3843,
                  kit_id: 36,
                  tipology_id: 40,
                  created_at: "2022-09-15T16:38:58.000000Z",
                  updated_at: "2022-09-15T16:38:58.000000Z",
                  tipology: {
                    id: 40,
                    name: "TIPO 07\t- 52,90m\u00b2",
                    description:
                      "Living, banho social, home office, su\u00edte master e balc\u00e3o.",
                    image:
                      "https://qsuite-buckets.s3.us-west-2.amazonaws.com/tipologies/4g0jftBWNO.jpg",
                    company_id: 4,
                    created_at: "2022-03-16T13:35:02.000000Z",
                    updated_at: "2022-08-19T17:20:26.000000Z",
                    code: "T7P4",
                    color: "#0cad00",
                  },
                },
                {
                  id: 3844,
                  kit_id: 36,
                  tipology_id: 42,
                  created_at: "2022-09-15T16:38:58.000000Z",
                  updated_at: "2022-09-15T16:38:58.000000Z",
                  tipology: {
                    id: 42,
                    name: "TIPO 02 - 66,95m\u00b2",
                    description:
                      "Living, lavabo, home office com banho, su\u00edte master e terra\u00e7o.",
                    image:
                      "https://qsuite-buckets.s3.us-west-2.amazonaws.com/tipologies/mWRcGbNfnu.jpg",
                    company_id: 4,
                    created_at: "2022-08-11T17:27:33.000000Z",
                    updated_at: "2022-08-25T11:58:19.000000Z",
                    code: "T2P8*",
                    color: "#0084ff",
                  },
                },
                {
                  id: 3845,
                  kit_id: 36,
                  tipology_id: 43,
                  created_at: "2022-09-15T16:38:58.000000Z",
                  updated_at: "2022-09-15T16:38:58.000000Z",
                  tipology: {
                    id: 43,
                    name: "TIPO 02 - 66,95m\u00b2",
                    description:
                      "Living, lavabo, home office com banho, su\u00edte master e terra\u00e7o.",
                    image:
                      "https://qsuite-buckets.s3.us-west-2.amazonaws.com/tipologies/g4Jl29nToE.jpg",
                    company_id: 4,
                    created_at: "2022-08-25T11:57:23.000000Z",
                    updated_at: "2022-08-25T11:57:23.000000Z",
                    code: "T2P7*",
                    color: "#0084ff",
                  },
                },
                {
                  id: 3846,
                  kit_id: 36,
                  tipology_id: 44,
                  created_at: "2022-09-15T16:38:58.000000Z",
                  updated_at: "2022-09-15T16:38:58.000000Z",
                  tipology: {
                    id: 44,
                    name: "TIPO 05 - 51,50m\u00b2",
                    description: "Studio com banho e balc\u00e3o.",
                    image:
                      "https://qsuite-buckets.s3.us-west-2.amazonaws.com/tipologies/hooYXdqABD.jpg",
                    company_id: 4,
                    created_at: "2022-09-15T16:28:38.000000Z",
                    updated_at: "2022-09-15T16:28:56.000000Z",
                    code: "T5P7*",
                    color: "#c800ff",
                  },
                },
              ],
            },
            {
              id: 37,
              name: "COMBO_DECORA\u00c7\u00c3O",
              description:
                "Combo com decora\u00e7\u00f5es para todo o apartamento.",
              exhibition: "GRADE",
              price: 8175.6,
              category_id: 2,
              gallery_id: 16,
              enterprise_id: 13,
              company_id: 4,
              deleted: 0,
              created_at: "2022-04-07T19:49:34.000000Z",
              updated_at: "2022-09-15T16:45:38.000000Z",
              type: "SECONDARY",
              decoration_kit_id: 7,
              is_decoration: 1,
              order: 8,
              free_secondary: 0,
              master_kit: null,
              products: [
                {
                  id: 15492,
                  kit_id: 37,
                  product_id: 70,
                  created_at: "2022-09-15T16:45:38.000000Z",
                  updated_at: "2022-09-15T16:45:38.000000Z",
                },
                {
                  id: 15493,
                  kit_id: 37,
                  product_id: 71,
                  created_at: "2022-09-15T16:45:38.000000Z",
                  updated_at: "2022-09-15T16:45:38.000000Z",
                },
                {
                  id: 15494,
                  kit_id: 37,
                  product_id: 73,
                  created_at: "2022-09-15T16:45:38.000000Z",
                  updated_at: "2022-09-15T16:45:38.000000Z",
                },
                {
                  id: 15495,
                  kit_id: 37,
                  product_id: 84,
                  created_at: "2022-09-15T16:45:38.000000Z",
                  updated_at: "2022-09-15T16:45:38.000000Z",
                },
                {
                  id: 15496,
                  kit_id: 37,
                  product_id: 80,
                  created_at: "2022-09-15T16:45:38.000000Z",
                  updated_at: "2022-09-15T16:45:38.000000Z",
                },
                {
                  id: 15497,
                  kit_id: 37,
                  product_id: 86,
                  created_at: "2022-09-15T16:45:38.000000Z",
                  updated_at: "2022-09-15T16:45:38.000000Z",
                },
                {
                  id: 15498,
                  kit_id: 37,
                  product_id: 92,
                  created_at: "2022-09-15T16:45:38.000000Z",
                  updated_at: "2022-09-15T16:45:38.000000Z",
                },
                {
                  id: 15499,
                  kit_id: 37,
                  product_id: 95,
                  created_at: "2022-09-15T16:45:38.000000Z",
                  updated_at: "2022-09-15T16:45:38.000000Z",
                },
                {
                  id: 15500,
                  kit_id: 37,
                  product_id: 96,
                  created_at: "2022-09-15T16:45:38.000000Z",
                  updated_at: "2022-09-15T16:45:38.000000Z",
                },
                {
                  id: 15501,
                  kit_id: 37,
                  product_id: 100,
                  created_at: "2022-09-15T16:45:38.000000Z",
                  updated_at: "2022-09-15T16:45:38.000000Z",
                },
                {
                  id: 15502,
                  kit_id: 37,
                  product_id: 103,
                  created_at: "2022-09-15T16:45:38.000000Z",
                  updated_at: "2022-09-15T16:45:38.000000Z",
                },
              ],
              tipologies: [
                {
                  id: 3900,
                  kit_id: 37,
                  tipology_id: 1,
                  created_at: "2022-09-15T16:45:38.000000Z",
                  updated_at: "2022-09-15T16:45:38.000000Z",
                  tipology: {
                    id: 1,
                    name: "TIPO 01 - 99,95m\u00b2",
                    description:
                      "Living, banho social, home office, su\u00edte master e terra\u00e7o.",
                    image:
                      "https://qsuite-buckets.s3.us-west-2.amazonaws.com/tipologies/Vs2yIzcnRO.jpg",
                    company_id: 4,
                    created_at: "2022-03-10T14:20:12.000000Z",
                    updated_at: "2022-08-19T16:43:41.000000Z",
                    code: "T1P1",
                    color: "#fbff00",
                  },
                },
                {
                  id: 3901,
                  kit_id: 37,
                  tipology_id: 2,
                  created_at: "2022-09-15T16:45:39.000000Z",
                  updated_at: "2022-09-15T16:45:39.000000Z",
                  tipology: {
                    id: 2,
                    name: "TIPO 01 - 92,75m\u00b2",
                    description:
                      "Living, banho social, home office, su\u00edte master e terra\u00e7o.",
                    image:
                      "https://qsuite-buckets.s3.us-west-2.amazonaws.com/tipologies/0ZZNoFGJAS.jpg",
                    company_id: 4,
                    created_at: "2022-03-10T14:44:40.000000Z",
                    updated_at: "2022-08-19T16:44:04.000000Z",
                    code: "T1P2",
                    color: "#fbff00",
                  },
                },
                {
                  id: 3902,
                  kit_id: 37,
                  tipology_id: 3,
                  created_at: "2022-09-15T16:45:39.000000Z",
                  updated_at: "2022-09-15T16:45:39.000000Z",
                  tipology: {
                    id: 3,
                    name: "TIPO 01 - 69,61m\u00b2",
                    description:
                      "Living, banho social, home office, su\u00edte master e terra\u00e7o.",
                    image:
                      "https://qsuite-buckets.s3.us-west-2.amazonaws.com/tipologies/wUqabxozY3.jpg",
                    company_id: 4,
                    created_at: "2022-03-11T00:16:01.000000Z",
                    updated_at: "2022-08-19T16:44:53.000000Z",
                    code: "T1P3",
                    color: "#fbff00",
                  },
                },
                {
                  id: 3903,
                  kit_id: 37,
                  tipology_id: 4,
                  created_at: "2022-09-15T16:45:39.000000Z",
                  updated_at: "2022-09-15T16:45:39.000000Z",
                  tipology: {
                    id: 4,
                    name: "TIPO 01 - 55,97m\u00b2",
                    description:
                      "Living, banho social, home office, su\u00edte master e laje t\u00e9cnica.",
                    image:
                      "https://qsuite-buckets.s3.us-west-2.amazonaws.com/tipologies/FaQEhIpP9Y.jpg",
                    company_id: 4,
                    created_at: "2022-03-11T00:16:21.000000Z",
                    updated_at: "2022-08-19T16:45:26.000000Z",
                    code: "T1P4",
                    color: "#fbff00",
                  },
                },
                {
                  id: 3904,
                  kit_id: 37,
                  tipology_id: 5,
                  created_at: "2022-09-15T16:45:39.000000Z",
                  updated_at: "2022-09-15T16:45:39.000000Z",
                  tipology: {
                    id: 5,
                    name: "TIPO 02\t- 72,08 m\u00b2",
                    description:
                      "Living, lavabo, home office com banho, su\u00edte master e laje t\u00e9cnica.",
                    image:
                      "https://qsuite-buckets.s3.us-west-2.amazonaws.com/tipologies/h5zs6ZMt0U.jpg",
                    company_id: 4,
                    created_at: "2022-03-16T13:14:07.000000Z",
                    updated_at: "2022-08-19T16:47:09.000000Z",
                    code: "T2P1",
                    color: "#0084ff",
                  },
                },
                {
                  id: 3905,
                  kit_id: 37,
                  tipology_id: 6,
                  created_at: "2022-09-15T16:45:39.000000Z",
                  updated_at: "2022-09-15T16:45:39.000000Z",
                  tipology: {
                    id: 6,
                    name: "TIPO 02 - 149,88m\u00b2",
                    description:
                      "Living, lavabo, su\u00edte, su\u00edte master e terra\u00e7o.",
                    image:
                      "https://qsuite-buckets.s3.us-west-2.amazonaws.com/tipologies/OYQMiVd7kz.jpg",
                    company_id: 4,
                    created_at: "2022-03-16T13:14:46.000000Z",
                    updated_at: "2022-08-19T16:58:07.000000Z",
                    code: "T2P2",
                    color: "#0084ff",
                  },
                },
                {
                  id: 3906,
                  kit_id: 37,
                  tipology_id: 7,
                  created_at: "2022-09-15T16:45:39.000000Z",
                  updated_at: "2022-09-15T16:45:39.000000Z",
                  tipology: {
                    id: 7,
                    name: "TIPO 02\t- 80,91m\u00b2",
                    description:
                      "Living, lavabo, su\u00edte, su\u00edte master e terra\u00e7o.",
                    image:
                      "https://qsuite-buckets.s3.us-west-2.amazonaws.com/tipologies/7p8WztbloW.jpg",
                    company_id: 4,
                    created_at: "2022-03-16T13:15:25.000000Z",
                    updated_at: "2022-08-19T16:57:46.000000Z",
                    code: "T2P3",
                    color: "#0084ff",
                  },
                },
                {
                  id: 3907,
                  kit_id: 37,
                  tipology_id: 8,
                  created_at: "2022-09-15T16:45:39.000000Z",
                  updated_at: "2022-09-15T16:45:39.000000Z",
                  tipology: {
                    id: 8,
                    name: "TIPO 02\t- 73,06m\u00b2",
                    description:
                      "Living, lavabo, su\u00edte, su\u00edte master e laje t\u00e9cnica.",
                    image:
                      "https://qsuite-buckets.s3.us-west-2.amazonaws.com/tipologies/rdCR2cVx6d.jpg",
                    company_id: 4,
                    created_at: "2022-03-16T13:15:57.000000Z",
                    updated_at: "2022-08-19T16:59:11.000000Z",
                    code: "T2P4",
                    color: "#0084ff",
                  },
                },
                {
                  id: 3908,
                  kit_id: 37,
                  tipology_id: 9,
                  created_at: "2022-09-15T16:45:39.000000Z",
                  updated_at: "2022-09-15T16:45:39.000000Z",
                  tipology: {
                    id: 9,
                    name: "TIPO 02\t- 77,13m\u00b2",
                    description:
                      "Living, lavabo, home office com banho, su\u00edte master e terra\u00e7o.",
                    image:
                      "https://qsuite-buckets.s3.us-west-2.amazonaws.com/tipologies/lifNnFx33o.jpg",
                    company_id: 4,
                    created_at: "2022-03-16T13:16:27.000000Z",
                    updated_at: "2022-08-19T16:59:48.000000Z",
                    code: "T2P5",
                    color: "#0084ff",
                  },
                },
                {
                  id: 3909,
                  kit_id: 37,
                  tipology_id: 10,
                  created_at: "2022-09-15T16:45:39.000000Z",
                  updated_at: "2022-09-15T16:45:39.000000Z",
                  tipology: {
                    id: 10,
                    name: "TIPO 02\t- 77,11m\u00b2",
                    description:
                      "Living, lavabo, home office com banho, su\u00edte master e terra\u00e7o.",
                    image:
                      "https://qsuite-buckets.s3.us-west-2.amazonaws.com/tipologies/INcyJuNCYJ.jpg",
                    company_id: 4,
                    created_at: "2022-03-16T13:16:56.000000Z",
                    updated_at: "2022-08-19T17:00:12.000000Z",
                    code: "T2P6",
                    color: "#0084ff",
                  },
                },
                {
                  id: 3910,
                  kit_id: 37,
                  tipology_id: 11,
                  created_at: "2022-09-15T16:45:39.000000Z",
                  updated_at: "2022-09-15T16:45:39.000000Z",
                  tipology: {
                    id: 11,
                    name: "TIPO 02\t- 66,95m\u00b2",
                    description:
                      "Living, lavabo, home office com banho, su\u00edte master e terra\u00e7o.",
                    image:
                      "https://qsuite-buckets.s3.us-west-2.amazonaws.com/tipologies/VZGEYkqeCH.jpg",
                    company_id: 4,
                    created_at: "2022-03-16T13:17:41.000000Z",
                    updated_at: "2022-08-19T17:00:35.000000Z",
                    code: "T2P7",
                    color: "#0084ff",
                  },
                },
                {
                  id: 3911,
                  kit_id: 37,
                  tipology_id: 12,
                  created_at: "2022-09-15T16:45:39.000000Z",
                  updated_at: "2022-09-15T16:45:39.000000Z",
                  tipology: {
                    id: 12,
                    name: "TIPO 02\t- 66,95m\u00b2",
                    description:
                      "Living, lavabo, home office com banho, su\u00edte master e terra\u00e7o.",
                    image:
                      "https://qsuite-buckets.s3.us-west-2.amazonaws.com/tipologies/HISWgMDtvT.jpg",
                    company_id: 4,
                    created_at: "2022-03-16T13:18:18.000000Z",
                    updated_at: "2022-08-19T17:01:01.000000Z",
                    code: "T2P8",
                    color: "#0084ff",
                  },
                },
                {
                  id: 3912,
                  kit_id: 37,
                  tipology_id: 13,
                  created_at: "2022-09-15T16:45:39.000000Z",
                  updated_at: "2022-09-15T16:45:39.000000Z",
                  tipology: {
                    id: 13,
                    name: "TIPO 03\t- 80,39m\u00b2",
                    description:
                      "Living, lavabo, su\u00edte, su\u00edte master e balc\u00e3o.",
                    image:
                      "https://qsuite-buckets.s3.us-west-2.amazonaws.com/tipologies/gsxbopSSHs.jpg",
                    company_id: 4,
                    created_at: "2022-03-16T13:18:45.000000Z",
                    updated_at: "2022-08-19T17:01:50.000000Z",
                    code: "T3P1",
                    color: "#ffa200",
                  },
                },
                {
                  id: 3913,
                  kit_id: 37,
                  tipology_id: 14,
                  created_at: "2022-09-15T16:45:39.000000Z",
                  updated_at: "2022-09-15T16:45:39.000000Z",
                  tipology: {
                    id: 14,
                    name: "TIPO 03\t- 75,97m\u00b2",
                    description:
                      "Living, lavabo, su\u00edte, su\u00edte master e varanda.",
                    image:
                      "https://qsuite-buckets.s3.us-west-2.amazonaws.com/tipologies/vmxUEHaRAQ.jpg",
                    company_id: 4,
                    created_at: "2022-03-16T13:19:20.000000Z",
                    updated_at: "2022-08-19T17:04:12.000000Z",
                    code: "T3P2",
                    color: "#ffa200",
                  },
                },
                {
                  id: 3914,
                  kit_id: 37,
                  tipology_id: 15,
                  created_at: "2022-09-15T16:45:39.000000Z",
                  updated_at: "2022-09-15T16:45:39.000000Z",
                  tipology: {
                    id: 15,
                    name: "TIPO 03\t- 75,97m\u00b2",
                    description:
                      "Living, lavabo, su\u00edte, su\u00edte master e varanda.",
                    image:
                      "https://qsuite-buckets.s3.us-west-2.amazonaws.com/tipologies/yhulEMHReJ.jpg",
                    company_id: 4,
                    created_at: "2022-03-16T13:19:33.000000Z",
                    updated_at: "2022-03-16T13:19:33.000000Z",
                    code: "T3P2",
                    color: null,
                  },
                },
                {
                  id: 3915,
                  kit_id: 37,
                  tipology_id: 16,
                  created_at: "2022-09-15T16:45:39.000000Z",
                  updated_at: "2022-09-15T16:45:39.000000Z",
                  tipology: {
                    id: 16,
                    name: "TIPO 03\t- 90,35m\u00b2",
                    description:
                      "Living, lavabo, su\u00edte, su\u00edte master e varanda.",
                    image:
                      "https://qsuite-buckets.s3.us-west-2.amazonaws.com/tipologies/obVtY1Kzfp.jpg",
                    company_id: 4,
                    created_at: "2022-03-16T13:20:01.000000Z",
                    updated_at: "2022-08-19T16:42:21.000000Z",
                    code: "T3P3",
                    color: "#ffa200",
                  },
                },
                {
                  id: 3916,
                  kit_id: 37,
                  tipology_id: 17,
                  created_at: "2022-09-15T16:45:39.000000Z",
                  updated_at: "2022-09-15T16:45:39.000000Z",
                  tipology: {
                    id: 17,
                    name: "TIPO 03\t- 75,96m\u00b2",
                    description:
                      "Living, lavabo, su\u00edte, su\u00edte master e varanda.",
                    image:
                      "https://qsuite-buckets.s3.us-west-2.amazonaws.com/tipologies/XTGTB4n3Sm.jpg",
                    company_id: 4,
                    created_at: "2022-03-16T13:20:28.000000Z",
                    updated_at: "2022-08-19T17:06:38.000000Z",
                    code: "T3P4",
                    color: "#ffa200",
                  },
                },
                {
                  id: 3917,
                  kit_id: 37,
                  tipology_id: 18,
                  created_at: "2022-09-15T16:45:39.000000Z",
                  updated_at: "2022-09-15T16:45:39.000000Z",
                  tipology: {
                    id: 18,
                    name: "TIPO 03 - 80,76m\u00b2",
                    description:
                      "Living, lavabo, su\u00edte, su\u00edte master e varanda.",
                    image:
                      "https://qsuite-buckets.s3.us-west-2.amazonaws.com/tipologies/4sz7SO65z0.jpg",
                    company_id: 4,
                    created_at: "2022-03-16T13:21:05.000000Z",
                    updated_at: "2022-08-19T17:07:08.000000Z",
                    code: "T3P5",
                    color: "#ffa200",
                  },
                },
                {
                  id: 3918,
                  kit_id: 37,
                  tipology_id: 19,
                  created_at: "2022-09-15T16:45:39.000000Z",
                  updated_at: "2022-09-15T16:45:39.000000Z",
                  tipology: {
                    id: 19,
                    name: "TIPO 04\t- 70,68m\u00b2",
                    description:
                      "Living, banho social, home office, su\u00edte master e balc\u00e3o.",
                    image:
                      "https://qsuite-buckets.s3.us-west-2.amazonaws.com/tipologies/AJbYiflLt0.jpg",
                    company_id: 4,
                    created_at: "2022-03-16T13:21:33.000000Z",
                    updated_at: "2022-08-19T17:07:54.000000Z",
                    code: "T4P1",
                    color: "#ff00ea",
                  },
                },
                {
                  id: 3919,
                  kit_id: 37,
                  tipology_id: 20,
                  created_at: "2022-09-15T16:45:39.000000Z",
                  updated_at: "2022-09-15T16:45:39.000000Z",
                  tipology: {
                    id: 20,
                    name: "TIPO 05\t- 72,08m\u00b2",
                    description: "Studio com banho e terra\u00e7o.",
                    image:
                      "https://qsuite-buckets.s3.us-west-2.amazonaws.com/tipologies/dO0KyjaRjh.jpg",
                    company_id: 4,
                    created_at: "2022-03-16T13:22:00.000000Z",
                    updated_at: "2022-08-19T17:08:25.000000Z",
                    code: "T5P1",
                    color: "#c800ff",
                  },
                },
                {
                  id: 3920,
                  kit_id: 37,
                  tipology_id: 21,
                  created_at: "2022-09-15T16:45:39.000000Z",
                  updated_at: "2022-09-15T16:45:39.000000Z",
                  tipology: {
                    id: 21,
                    name: "TIPO 05\t- 47,01m\u00b2",
                    description: "Studio com banho e balc\u00e3o.",
                    image:
                      "https://qsuite-buckets.s3.us-west-2.amazonaws.com/tipologies/FkdjpB3Had.jpg",
                    company_id: 4,
                    created_at: "2022-03-16T13:22:45.000000Z",
                    updated_at: "2022-08-19T17:08:59.000000Z",
                    code: "T5P2",
                    color: "#c800ff",
                  },
                },
                {
                  id: 3921,
                  kit_id: 37,
                  tipology_id: 22,
                  created_at: "2022-09-15T16:45:39.000000Z",
                  updated_at: "2022-09-15T16:45:39.000000Z",
                  tipology: {
                    id: 22,
                    name: "TIPO 05\t- 52,49m\u00b2",
                    description: "Studio com banho e balc\u00e3o.",
                    image:
                      "https://qsuite-buckets.s3.us-west-2.amazonaws.com/tipologies/lipFkfGJZn.jpg",
                    company_id: 4,
                    created_at: "2022-03-16T13:23:16.000000Z",
                    updated_at: "2022-08-19T16:41:02.000000Z",
                    code: "T5P3",
                    color: "#c800ff",
                  },
                },
                {
                  id: 3922,
                  kit_id: 37,
                  tipology_id: 23,
                  created_at: "2022-09-15T16:45:39.000000Z",
                  updated_at: "2022-09-15T16:45:39.000000Z",
                  tipology: {
                    id: 23,
                    name: "TIPO 05\t- 60,71m\u00b2",
                    description: "Studio com banho e laje t\u00e9cnica.",
                    image:
                      "https://qsuite-buckets.s3.us-west-2.amazonaws.com/tipologies/Hx7LLObb0s.jpg",
                    company_id: 4,
                    created_at: "2022-03-16T13:23:46.000000Z",
                    updated_at: "2022-08-19T17:10:36.000000Z",
                    code: "T5P4",
                    color: "#c800ff",
                  },
                },
                {
                  id: 3923,
                  kit_id: 37,
                  tipology_id: 24,
                  created_at: "2022-09-15T16:45:39.000000Z",
                  updated_at: "2022-09-15T16:45:39.000000Z",
                  tipology: {
                    id: 24,
                    name: "TIPO 05\t- 66,37m\u00b2",
                    description: "Studio com banho e terra\u00e7o.",
                    image:
                      "https://qsuite-buckets.s3.us-west-2.amazonaws.com/tipologies/nrmAcKaLMR.jpg",
                    company_id: 4,
                    created_at: "2022-03-16T13:24:14.000000Z",
                    updated_at: "2022-08-19T17:11:15.000000Z",
                    code: "T5P5",
                    color: "#c800ff",
                  },
                },
                {
                  id: 3924,
                  kit_id: 37,
                  tipology_id: 25,
                  created_at: "2022-09-15T16:45:39.000000Z",
                  updated_at: "2022-09-15T16:45:39.000000Z",
                  tipology: {
                    id: 25,
                    name: "TIPO 05\t- 66,43m\u00b2",
                    description: "Studio com banho e terra\u00e7o.",
                    image:
                      "https://qsuite-buckets.s3.us-west-2.amazonaws.com/tipologies/k1p0tOXzf7.jpg",
                    company_id: 4,
                    created_at: "2022-03-16T13:24:41.000000Z",
                    updated_at: "2022-08-19T17:11:52.000000Z",
                    code: "T5P6",
                    color: "#c800ff",
                  },
                },
                {
                  id: 3925,
                  kit_id: 37,
                  tipology_id: 26,
                  created_at: "2022-09-15T16:45:39.000000Z",
                  updated_at: "2022-09-15T16:45:39.000000Z",
                  tipology: {
                    id: 26,
                    name: "TIPO 05\t- 51,44m\u00b2",
                    description: "Studio com banho e balc\u00e3o.",
                    image:
                      "https://qsuite-buckets.s3.us-west-2.amazonaws.com/tipologies/8Vqb4WjPj6.jpg",
                    company_id: 4,
                    created_at: "2022-03-16T13:25:08.000000Z",
                    updated_at: "2022-08-19T17:12:33.000000Z",
                    code: "T5P7",
                    color: "#c800ff",
                  },
                },
                {
                  id: 3926,
                  kit_id: 37,
                  tipology_id: 27,
                  created_at: "2022-09-15T16:45:39.000000Z",
                  updated_at: "2022-09-15T16:45:39.000000Z",
                  tipology: {
                    id: 27,
                    name: "TIPO 05\t- 47,01m\u00b2",
                    description: "Studio com banho e balc\u00e3o.",
                    image:
                      "https://qsuite-buckets.s3.us-west-2.amazonaws.com/tipologies/976rjj2xVO.jpg",
                    company_id: 4,
                    created_at: "2022-03-16T13:27:07.000000Z",
                    updated_at: "2022-08-19T17:13:18.000000Z",
                    code: "T5P8",
                    color: "#c800ff",
                  },
                },
                {
                  id: 3927,
                  kit_id: 37,
                  tipology_id: 28,
                  created_at: "2022-09-15T16:45:39.000000Z",
                  updated_at: "2022-09-15T16:45:39.000000Z",
                  tipology: {
                    id: 28,
                    name: "TIPO 05\t- 37,89m\u00b2",
                    description: "Studio com banho e laje t\u00e9cnica.",
                    image:
                      "https://qsuite-buckets.s3.us-west-2.amazonaws.com/tipologies/9P86YXGDQQ.jpg",
                    company_id: 4,
                    created_at: "2022-03-16T13:28:00.000000Z",
                    updated_at: "2022-08-19T17:13:38.000000Z",
                    code: "T5P9",
                    color: "#c800ff",
                  },
                },
                {
                  id: 3928,
                  kit_id: 37,
                  tipology_id: 29,
                  created_at: "2022-09-15T16:45:39.000000Z",
                  updated_at: "2022-09-15T16:45:39.000000Z",
                  tipology: {
                    id: 29,
                    name: "TIPO 06\t- 97,73m\u00b2",
                    description:
                      "Living, lavabo, su\u00edte, su\u00edte master e terra\u00e7o.",
                    image:
                      "https://qsuite-buckets.s3.us-west-2.amazonaws.com/tipologies/nKQC982QoT.jpg",
                    company_id: 4,
                    created_at: "2022-03-16T13:28:27.000000Z",
                    updated_at: "2022-08-19T17:14:16.000000Z",
                    code: "T6P1",
                    color: "#ff0000",
                  },
                },
                {
                  id: 3929,
                  kit_id: 37,
                  tipology_id: 35,
                  created_at: "2022-09-15T16:45:39.000000Z",
                  updated_at: "2022-09-15T16:45:39.000000Z",
                  tipology: {
                    id: 35,
                    name: "TIPO 06\t- 69,81m\u00b2",
                    description:
                      "Living, lavabo, su\u00edte, su\u00edte master e laje t\u00e9cnica.",
                    image:
                      "https://qsuite-buckets.s3.us-west-2.amazonaws.com/tipologies/5iKD9tcHq7.jpg",
                    company_id: 4,
                    created_at: "2022-03-16T13:31:33.000000Z",
                    updated_at: "2022-08-19T17:16:58.000000Z",
                    code: "T6P7",
                    color: "#ff0000",
                  },
                },
                {
                  id: 3930,
                  kit_id: 37,
                  tipology_id: 34,
                  created_at: "2022-09-15T16:45:39.000000Z",
                  updated_at: "2022-09-15T16:45:39.000000Z",
                  tipology: {
                    id: 34,
                    name: "TIPO 06\t- 69,81m\u00b2",
                    description:
                      "Living, lavabo, su\u00edte, su\u00edte master e laje t\u00e9cnica.",
                    image:
                      "https://qsuite-buckets.s3.us-west-2.amazonaws.com/tipologies/bBh0RWjH3a.jpg",
                    company_id: 4,
                    created_at: "2022-03-16T13:31:04.000000Z",
                    updated_at: "2022-08-19T17:16:22.000000Z",
                    code: "T6P6",
                    color: "#ff0000",
                  },
                },
                {
                  id: 3931,
                  kit_id: 37,
                  tipology_id: 33,
                  created_at: "2022-09-15T16:45:39.000000Z",
                  updated_at: "2022-09-15T16:45:39.000000Z",
                  tipology: {
                    id: 33,
                    name: "TIPO 06\t- 71,89m\u00b2",
                    description:
                      "Living, lavabo, su\u00edte, su\u00edte master e balc\u00e3o.",
                    image:
                      "https://qsuite-buckets.s3.us-west-2.amazonaws.com/tipologies/us7WEVxfNc.jpg",
                    company_id: 4,
                    created_at: "2022-03-16T13:30:30.000000Z",
                    updated_at: "2022-08-19T17:15:56.000000Z",
                    code: "T6P5",
                    color: "#ff0000",
                  },
                },
                {
                  id: 3932,
                  kit_id: 37,
                  tipology_id: 32,
                  created_at: "2022-09-15T16:45:39.000000Z",
                  updated_at: "2022-09-15T16:45:39.000000Z",
                  tipology: {
                    id: 32,
                    name: "TIPO 06\t- 97,73m\u00b2",
                    description:
                      "Living, lavabo, su\u00edte, su\u00edte master e terra\u00e7o.",
                    image:
                      "https://qsuite-buckets.s3.us-west-2.amazonaws.com/tipologies/7zM76EBH4X.jpg",
                    company_id: 4,
                    created_at: "2022-03-16T13:29:54.000000Z",
                    updated_at: "2022-08-19T17:15:32.000000Z",
                    code: "T6P4",
                    color: "#ff0000",
                  },
                },
                {
                  id: 3933,
                  kit_id: 37,
                  tipology_id: 31,
                  created_at: "2022-09-15T16:45:39.000000Z",
                  updated_at: "2022-09-15T16:45:39.000000Z",
                  tipology: {
                    id: 31,
                    name: "TIPO 06\t- 99,99m\u00b2",
                    description:
                      "Living, lavabo, su\u00edte, su\u00edte master e terra\u00e7o.",
                    image:
                      "https://qsuite-buckets.s3.us-west-2.amazonaws.com/tipologies/S14y16YIim.jpg",
                    company_id: 4,
                    created_at: "2022-03-16T13:29:24.000000Z",
                    updated_at: "2022-08-19T17:15:06.000000Z",
                    code: "T6P3",
                    color: "#ff0000",
                  },
                },
                {
                  id: 3934,
                  kit_id: 37,
                  tipology_id: 30,
                  created_at: "2022-09-15T16:45:39.000000Z",
                  updated_at: "2022-09-15T16:45:39.000000Z",
                  tipology: {
                    id: 30,
                    name: "TIPO 06\t- 99,99m\u00b2",
                    description:
                      "Living, lavabo, su\u00edte, su\u00edte master e terra\u00e7o.",
                    image:
                      "https://qsuite-buckets.s3.us-west-2.amazonaws.com/tipologies/dWs4YcNgJJ.jpg",
                    company_id: 4,
                    created_at: "2022-03-16T13:28:54.000000Z",
                    updated_at: "2022-08-19T17:14:42.000000Z",
                    code: "T6P2",
                    color: "#ff0000",
                  },
                },
                {
                  id: 3935,
                  kit_id: 37,
                  tipology_id: 36,
                  created_at: "2022-09-15T16:45:39.000000Z",
                  updated_at: "2022-09-15T16:45:39.000000Z",
                  tipology: {
                    id: 36,
                    name: "TIPO 06\t- 71,89m\u00b2",
                    description:
                      "Living, lavabo, su\u00edte, su\u00edte master e laje t\u00e9cnica.",
                    image:
                      "https://qsuite-buckets.s3.us-west-2.amazonaws.com/tipologies/fX5TviLoMA.jpg",
                    company_id: 4,
                    created_at: "2022-03-16T13:32:17.000000Z",
                    updated_at: "2022-08-19T17:17:31.000000Z",
                    code: "T6P8",
                    color: "#ff0000",
                  },
                },
                {
                  id: 3936,
                  kit_id: 37,
                  tipology_id: 37,
                  created_at: "2022-09-15T16:45:39.000000Z",
                  updated_at: "2022-09-15T16:45:39.000000Z",
                  tipology: {
                    id: 37,
                    name: "TIPO 07\t- 71,94m\u00b2",
                    description:
                      "Living, banho social, home office, su\u00edte master e terra\u00e7o.",
                    image:
                      "https://qsuite-buckets.s3.us-west-2.amazonaws.com/tipologies/yG3ZdpRC3M.jpg",
                    company_id: 4,
                    created_at: "2022-03-16T13:33:20.000000Z",
                    updated_at: "2022-08-19T17:18:17.000000Z",
                    code: "T7P1",
                    color: "#0cad00",
                  },
                },
                {
                  id: 3937,
                  kit_id: 37,
                  tipology_id: 38,
                  created_at: "2022-09-15T16:45:39.000000Z",
                  updated_at: "2022-09-15T16:45:39.000000Z",
                  tipology: {
                    id: 38,
                    name: "TIPO 07\t- 71,94m\u00b2",
                    description:
                      "Living, banho social, home office, su\u00edte master e terra\u00e7o.",
                    image:
                      "https://qsuite-buckets.s3.us-west-2.amazonaws.com/tipologies/oVNwRRYe7U.jpg",
                    company_id: 4,
                    created_at: "2022-03-16T13:34:08.000000Z",
                    updated_at: "2022-08-19T17:19:00.000000Z",
                    code: "T7P2",
                    color: "#0cad00",
                  },
                },
                {
                  id: 3938,
                  kit_id: 37,
                  tipology_id: 39,
                  created_at: "2022-09-15T16:45:39.000000Z",
                  updated_at: "2022-09-15T16:45:39.000000Z",
                  tipology: {
                    id: 39,
                    name: "TIPO 07\t- 52,90m\u00b2",
                    description:
                      "Living, banho social, home office, su\u00edte master e balc\u00e3o.",
                    image:
                      "https://qsuite-buckets.s3.us-west-2.amazonaws.com/tipologies/Qrlx3FAVtd.jpg",
                    company_id: 4,
                    created_at: "2022-03-16T13:34:38.000000Z",
                    updated_at: "2022-08-19T17:19:52.000000Z",
                    code: "T7P3",
                    color: "#0cad00",
                  },
                },
                {
                  id: 3939,
                  kit_id: 37,
                  tipology_id: 40,
                  created_at: "2022-09-15T16:45:39.000000Z",
                  updated_at: "2022-09-15T16:45:39.000000Z",
                  tipology: {
                    id: 40,
                    name: "TIPO 07\t- 52,90m\u00b2",
                    description:
                      "Living, banho social, home office, su\u00edte master e balc\u00e3o.",
                    image:
                      "https://qsuite-buckets.s3.us-west-2.amazonaws.com/tipologies/4g0jftBWNO.jpg",
                    company_id: 4,
                    created_at: "2022-03-16T13:35:02.000000Z",
                    updated_at: "2022-08-19T17:20:26.000000Z",
                    code: "T7P4",
                    color: "#0cad00",
                  },
                },
                {
                  id: 3940,
                  kit_id: 37,
                  tipology_id: 42,
                  created_at: "2022-09-15T16:45:39.000000Z",
                  updated_at: "2022-09-15T16:45:39.000000Z",
                  tipology: {
                    id: 42,
                    name: "TIPO 02 - 66,95m\u00b2",
                    description:
                      "Living, lavabo, home office com banho, su\u00edte master e terra\u00e7o.",
                    image:
                      "https://qsuite-buckets.s3.us-west-2.amazonaws.com/tipologies/mWRcGbNfnu.jpg",
                    company_id: 4,
                    created_at: "2022-08-11T17:27:33.000000Z",
                    updated_at: "2022-08-25T11:58:19.000000Z",
                    code: "T2P8*",
                    color: "#0084ff",
                  },
                },
                {
                  id: 3941,
                  kit_id: 37,
                  tipology_id: 43,
                  created_at: "2022-09-15T16:45:39.000000Z",
                  updated_at: "2022-09-15T16:45:39.000000Z",
                  tipology: {
                    id: 43,
                    name: "TIPO 02 - 66,95m\u00b2",
                    description:
                      "Living, lavabo, home office com banho, su\u00edte master e terra\u00e7o.",
                    image:
                      "https://qsuite-buckets.s3.us-west-2.amazonaws.com/tipologies/g4Jl29nToE.jpg",
                    company_id: 4,
                    created_at: "2022-08-25T11:57:23.000000Z",
                    updated_at: "2022-08-25T11:57:23.000000Z",
                    code: "T2P7*",
                    color: "#0084ff",
                  },
                },
                {
                  id: 3942,
                  kit_id: 37,
                  tipology_id: 44,
                  created_at: "2022-09-15T16:45:39.000000Z",
                  updated_at: "2022-09-15T16:45:39.000000Z",
                  tipology: {
                    id: 44,
                    name: "TIPO 05 - 51,50m\u00b2",
                    description: "Studio com banho e balc\u00e3o.",
                    image:
                      "https://qsuite-buckets.s3.us-west-2.amazonaws.com/tipologies/hooYXdqABD.jpg",
                    company_id: 4,
                    created_at: "2022-09-15T16:28:38.000000Z",
                    updated_at: "2022-09-15T16:28:56.000000Z",
                    code: "T5P7*",
                    color: "#c800ff",
                  },
                },
              ],
            },
            {
              id: 38,
              name: "COMBO_UTILIDADES",
              description:
                "Kit com utens\u00edlios de cozinha, eletroport\u00e1teis e limpeza.",
              exhibition: "GRADE",
              price: 4078.7,
              category_id: 1,
              gallery_id: 31,
              enterprise_id: 13,
              company_id: 4,
              deleted: 0,
              created_at: "2022-08-24T14:02:15.000000Z",
              updated_at: "2022-09-15T16:39:36.000000Z",
              type: "SECONDARY",
              decoration_kit_id: 38,
              is_decoration: 0,
              order: 5,
              free_secondary: 0,
              master_kit: 0,
              products: [
                {
                  id: 15462,
                  kit_id: 38,
                  product_id: 105,
                  created_at: "2022-09-15T16:39:36.000000Z",
                  updated_at: "2022-09-15T16:39:36.000000Z",
                },
                {
                  id: 15463,
                  kit_id: 38,
                  product_id: 106,
                  created_at: "2022-09-15T16:39:36.000000Z",
                  updated_at: "2022-09-15T16:39:36.000000Z",
                },
                {
                  id: 15464,
                  kit_id: 38,
                  product_id: 126,
                  created_at: "2022-09-15T16:39:36.000000Z",
                  updated_at: "2022-09-15T16:39:36.000000Z",
                },
                {
                  id: 15465,
                  kit_id: 38,
                  product_id: 127,
                  created_at: "2022-09-15T16:39:36.000000Z",
                  updated_at: "2022-09-15T16:39:36.000000Z",
                },
                {
                  id: 15466,
                  kit_id: 38,
                  product_id: 128,
                  created_at: "2022-09-15T16:39:36.000000Z",
                  updated_at: "2022-09-15T16:39:36.000000Z",
                },
                {
                  id: 15467,
                  kit_id: 38,
                  product_id: 129,
                  created_at: "2022-09-15T16:39:36.000000Z",
                  updated_at: "2022-09-15T16:39:36.000000Z",
                },
                {
                  id: 15468,
                  kit_id: 38,
                  product_id: 139,
                  created_at: "2022-09-15T16:39:36.000000Z",
                  updated_at: "2022-09-15T16:39:36.000000Z",
                },
                {
                  id: 15469,
                  kit_id: 38,
                  product_id: 141,
                  created_at: "2022-09-15T16:39:36.000000Z",
                  updated_at: "2022-09-15T16:39:36.000000Z",
                },
                {
                  id: 15470,
                  kit_id: 38,
                  product_id: 142,
                  created_at: "2022-09-15T16:39:36.000000Z",
                  updated_at: "2022-09-15T16:39:36.000000Z",
                },
                {
                  id: 15471,
                  kit_id: 38,
                  product_id: 143,
                  created_at: "2022-09-15T16:39:36.000000Z",
                  updated_at: "2022-09-15T16:39:36.000000Z",
                },
                {
                  id: 15472,
                  kit_id: 38,
                  product_id: 150,
                  created_at: "2022-09-15T16:39:36.000000Z",
                  updated_at: "2022-09-15T16:39:36.000000Z",
                },
                {
                  id: 15473,
                  kit_id: 38,
                  product_id: 149,
                  created_at: "2022-09-15T16:39:36.000000Z",
                  updated_at: "2022-09-15T16:39:36.000000Z",
                },
                {
                  id: 15474,
                  kit_id: 38,
                  product_id: 148,
                  created_at: "2022-09-15T16:39:36.000000Z",
                  updated_at: "2022-09-15T16:39:36.000000Z",
                },
                {
                  id: 15475,
                  kit_id: 38,
                  product_id: 147,
                  created_at: "2022-09-15T16:39:36.000000Z",
                  updated_at: "2022-09-15T16:39:36.000000Z",
                },
                {
                  id: 15476,
                  kit_id: 38,
                  product_id: 146,
                  created_at: "2022-09-15T16:39:36.000000Z",
                  updated_at: "2022-09-15T16:39:36.000000Z",
                },
                {
                  id: 15477,
                  kit_id: 38,
                  product_id: 145,
                  created_at: "2022-09-15T16:39:36.000000Z",
                  updated_at: "2022-09-15T16:39:36.000000Z",
                },
                {
                  id: 15478,
                  kit_id: 38,
                  product_id: 144,
                  created_at: "2022-09-15T16:39:36.000000Z",
                  updated_at: "2022-09-15T16:39:36.000000Z",
                },
                {
                  id: 15479,
                  kit_id: 38,
                  product_id: 179,
                  created_at: "2022-09-15T16:39:36.000000Z",
                  updated_at: "2022-09-15T16:39:36.000000Z",
                },
                {
                  id: 15480,
                  kit_id: 38,
                  product_id: 11,
                  created_at: "2022-09-15T16:39:36.000000Z",
                  updated_at: "2022-09-15T16:39:36.000000Z",
                },
              ],
              tipologies: [
                {
                  id: 3847,
                  kit_id: 38,
                  tipology_id: 28,
                  created_at: "2022-09-15T16:39:36.000000Z",
                  updated_at: "2022-09-15T16:39:36.000000Z",
                  tipology: {
                    id: 28,
                    name: "TIPO 05\t- 37,89m\u00b2",
                    description: "Studio com banho e laje t\u00e9cnica.",
                    image:
                      "https://qsuite-buckets.s3.us-west-2.amazonaws.com/tipologies/9P86YXGDQQ.jpg",
                    company_id: 4,
                    created_at: "2022-03-16T13:28:00.000000Z",
                    updated_at: "2022-08-19T17:13:38.000000Z",
                    code: "T5P9",
                    color: "#c800ff",
                  },
                },
                {
                  id: 3848,
                  kit_id: 38,
                  tipology_id: 27,
                  created_at: "2022-09-15T16:39:37.000000Z",
                  updated_at: "2022-09-15T16:39:37.000000Z",
                  tipology: {
                    id: 27,
                    name: "TIPO 05\t- 47,01m\u00b2",
                    description: "Studio com banho e balc\u00e3o.",
                    image:
                      "https://qsuite-buckets.s3.us-west-2.amazonaws.com/tipologies/976rjj2xVO.jpg",
                    company_id: 4,
                    created_at: "2022-03-16T13:27:07.000000Z",
                    updated_at: "2022-08-19T17:13:18.000000Z",
                    code: "T5P8",
                    color: "#c800ff",
                  },
                },
                {
                  id: 3849,
                  kit_id: 38,
                  tipology_id: 23,
                  created_at: "2022-09-15T16:39:37.000000Z",
                  updated_at: "2022-09-15T16:39:37.000000Z",
                  tipology: {
                    id: 23,
                    name: "TIPO 05\t- 60,71m\u00b2",
                    description: "Studio com banho e laje t\u00e9cnica.",
                    image:
                      "https://qsuite-buckets.s3.us-west-2.amazonaws.com/tipologies/Hx7LLObb0s.jpg",
                    company_id: 4,
                    created_at: "2022-03-16T13:23:46.000000Z",
                    updated_at: "2022-08-19T17:10:36.000000Z",
                    code: "T5P4",
                    color: "#c800ff",
                  },
                },
                {
                  id: 3850,
                  kit_id: 38,
                  tipology_id: 22,
                  created_at: "2022-09-15T16:39:37.000000Z",
                  updated_at: "2022-09-15T16:39:37.000000Z",
                  tipology: {
                    id: 22,
                    name: "TIPO 05\t- 52,49m\u00b2",
                    description: "Studio com banho e balc\u00e3o.",
                    image:
                      "https://qsuite-buckets.s3.us-west-2.amazonaws.com/tipologies/lipFkfGJZn.jpg",
                    company_id: 4,
                    created_at: "2022-03-16T13:23:16.000000Z",
                    updated_at: "2022-08-19T16:41:02.000000Z",
                    code: "T5P3",
                    color: "#c800ff",
                  },
                },
                {
                  id: 3851,
                  kit_id: 38,
                  tipology_id: 24,
                  created_at: "2022-09-15T16:39:37.000000Z",
                  updated_at: "2022-09-15T16:39:37.000000Z",
                  tipology: {
                    id: 24,
                    name: "TIPO 05\t- 66,37m\u00b2",
                    description: "Studio com banho e terra\u00e7o.",
                    image:
                      "https://qsuite-buckets.s3.us-west-2.amazonaws.com/tipologies/nrmAcKaLMR.jpg",
                    company_id: 4,
                    created_at: "2022-03-16T13:24:14.000000Z",
                    updated_at: "2022-08-19T17:11:15.000000Z",
                    code: "T5P5",
                    color: "#c800ff",
                  },
                },
                {
                  id: 3852,
                  kit_id: 38,
                  tipology_id: 25,
                  created_at: "2022-09-15T16:39:37.000000Z",
                  updated_at: "2022-09-15T16:39:37.000000Z",
                  tipology: {
                    id: 25,
                    name: "TIPO 05\t- 66,43m\u00b2",
                    description: "Studio com banho e terra\u00e7o.",
                    image:
                      "https://qsuite-buckets.s3.us-west-2.amazonaws.com/tipologies/k1p0tOXzf7.jpg",
                    company_id: 4,
                    created_at: "2022-03-16T13:24:41.000000Z",
                    updated_at: "2022-08-19T17:11:52.000000Z",
                    code: "T5P6",
                    color: "#c800ff",
                  },
                },
                {
                  id: 3853,
                  kit_id: 38,
                  tipology_id: 26,
                  created_at: "2022-09-15T16:39:37.000000Z",
                  updated_at: "2022-09-15T16:39:37.000000Z",
                  tipology: {
                    id: 26,
                    name: "TIPO 05\t- 51,44m\u00b2",
                    description: "Studio com banho e balc\u00e3o.",
                    image:
                      "https://qsuite-buckets.s3.us-west-2.amazonaws.com/tipologies/8Vqb4WjPj6.jpg",
                    company_id: 4,
                    created_at: "2022-03-16T13:25:08.000000Z",
                    updated_at: "2022-08-19T17:12:33.000000Z",
                    code: "T5P7",
                    color: "#c800ff",
                  },
                },
                {
                  id: 3854,
                  kit_id: 38,
                  tipology_id: 21,
                  created_at: "2022-09-15T16:39:37.000000Z",
                  updated_at: "2022-09-15T16:39:37.000000Z",
                  tipology: {
                    id: 21,
                    name: "TIPO 05\t- 47,01m\u00b2",
                    description: "Studio com banho e balc\u00e3o.",
                    image:
                      "https://qsuite-buckets.s3.us-west-2.amazonaws.com/tipologies/FkdjpB3Had.jpg",
                    company_id: 4,
                    created_at: "2022-03-16T13:22:45.000000Z",
                    updated_at: "2022-08-19T17:08:59.000000Z",
                    code: "T5P2",
                    color: "#c800ff",
                  },
                },
                {
                  id: 3855,
                  kit_id: 38,
                  tipology_id: 20,
                  created_at: "2022-09-15T16:39:37.000000Z",
                  updated_at: "2022-09-15T16:39:37.000000Z",
                  tipology: {
                    id: 20,
                    name: "TIPO 05\t- 72,08m\u00b2",
                    description: "Studio com banho e terra\u00e7o.",
                    image:
                      "https://qsuite-buckets.s3.us-west-2.amazonaws.com/tipologies/dO0KyjaRjh.jpg",
                    company_id: 4,
                    created_at: "2022-03-16T13:22:00.000000Z",
                    updated_at: "2022-08-19T17:08:25.000000Z",
                    code: "T5P1",
                    color: "#c800ff",
                  },
                },
                {
                  id: 3856,
                  kit_id: 38,
                  tipology_id: 44,
                  created_at: "2022-09-15T16:39:37.000000Z",
                  updated_at: "2022-09-15T16:39:37.000000Z",
                  tipology: {
                    id: 44,
                    name: "TIPO 05 - 51,50m\u00b2",
                    description: "Studio com banho e balc\u00e3o.",
                    image:
                      "https://qsuite-buckets.s3.us-west-2.amazonaws.com/tipologies/hooYXdqABD.jpg",
                    company_id: 4,
                    created_at: "2022-09-15T16:28:38.000000Z",
                    updated_at: "2022-09-15T16:28:56.000000Z",
                    code: "T5P7*",
                    color: "#c800ff",
                  },
                },
              ],
            },
          ];
          resp.forEach((element) => {
            if (element.type == "MAIN") {
              this.fetchKit(element.id);
              return;
            }
          });
        })
        .catch((err) => {
          console.log(err);
        });
    },
    fetchKit(id) {
      let data = {
        id: id,
      };

      service
        .read(data)
        .then((resp) => {
          this.kit = resp;
          this.kit.products = this.kit.products.reduce(function (r, a) {
            let name = a.product.subdivision
              ? a.product.subdivision.name
              : "Todos";
            r[name] = r[name] || [];
            r[name].push(a);
            return r;
          }, Object.create(null));
          this.kit.products = Object.keys(this.kit.products)
            .sort((a, b) => {
              let first = this.kit.products[a][0].product.subdivision;
              let secound = this.kit.products[b][0].product.subdivision;
              return first && secound ? first.order - secound.order : 0;
            })
            .reduce((obj, key) => {
              obj[key] = this.kit.products[key];
              return obj;
            }, {});

          let modal = bootstrap.Modal.getInstance(
            document.getElementById("menu-product")
          );
          modal.hide();
        })
        .catch((err) => {
          console.log(err);
        });
    },
  },
  mounted() {
    (function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:4932490,hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
     })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');
    let order = JSON.parse(localStorage.order);
    this.order = {
      user_id: this.user.id,
      itens: order.itens,
      unity_id: order.unity_id,
      enterprise_id: order.enterprise_id,
      tipology_id: order.tipology_id,
      kits: [],
    };
    this.fetchKits();
  },
};
</script>